import Flex from '@app/components/Flex';
import Grid from '@app/components/Grid';
import Heading from '@app/components/Heading';
import NonIdealState from '@app/components/NonIdealState';
import Pagination from '@app/components/Pagination';
import AiImage from '@app/containers/components/AiImage';
import AiImageViewerModal, { AiImageViewerModalProps } from '@app/containers/modals/AiImageViewerModal';
import { openModal } from '@app/containers/modals/store/events';
import { amountOfAiImagesOnPage } from './consts';
import { Endpoints } from '@app/data/consts';
import { ToastUtils } from '@app/data/utils';
import { usePageTitle, useScrollToTop } from '@app/hooks';
import { Icon, Intent, Spinner } from '@blueprintjs/core';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { AiImageFilterInput } from 'dy-frontend-http-repository/lib/modules/AiImage/inputs';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $client } from '../../store/states';
import { fetchAiImages } from './store/effects';
import { resetAiImages } from './store/events';
import { $aiImages } from './store/states';

const ClientAiImages: React.FC = () => {
    const { scrollToTop } = useScrollToTop();
    const { changeTitle } = usePageTitle('Client AI Images');
    const navigate = useNavigate();

    const aiImages = useStore($aiImages);
    const client = useStore($client);
    const isFetchingClientAiImages = useStore(fetchAiImages.pending);

    const [page, setPage] = useState(1);

    const handleLoadPage = async (newPage: number, search?: string) => {
        if (!client) return;

        // Common filters
        const filter: AiImageFilterInput = {
            user_id: client.id,
        };

        // Check if search was provided
        if (search) {
            filter['query'] = search;
        }

        const pageOffset = (newPage - 1) * amountOfAiImagesOnPage;

        try {
            await fetchAiImages({
                pagination: {
                    _pagination: { limit: amountOfAiImagesOnPage, offset: pageOffset },
                },
                filter,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `AI images for client with ID ${client.id} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to client tasks page
                navigate(Endpoints.CLIENT_TASKS.replace(':clientId', client.id));
            }
        }
    };

    useEffect(() => {
        handleLoadPage(page);

        // Scroll to top
        scrollToTop();

        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client AI Images - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    useEffect(() => {
        return () => {
            resetAiImages();
        };
    }, []);

    if (!client) {
        return null;
    }

    const renderAiImagesList = () => {
        // Client AI image were NOT fetched yet
        if (!aiImages) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Client AI image were fetched, but where are no AI image existed for this client
        if (aiImages.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No client AI images were found
                        </Heading>
                    }
                />
            );
        }

        return (
            <Grid container>
                {aiImages.items.map((aiImage, index) => (
                    <Grid
                        className="mb-2"
                        key={aiImage.id}
                        lg={4}
                        onClick={() =>
                            openModal<AiImageViewerModalProps>({
                                ModalComponent: AiImageViewerModal,
                                data: {
                                    currentFileIndex: index,
                                    images: aiImages.items,
                                },
                            })
                        }
                    >
                        <AiImage image={aiImage} />
                    </Grid>
                ))}
            </Grid>
        );
    };

    const renderPagination = () => {
        // Client AI image is not fetched yet
        if (aiImages === null) {
            return null;
        }

        // Check if offset paginator exist
        if (aiImages.paginator === null) {
            return;
        }

        // Only one page exist
        if (!aiImages.paginator.has_more && aiImages.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingClientAiImages}
                    hasMore={aiImages.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfAiImagesOnPage}
                    totalItems={aiImages.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <div>
            {/* Client AI image list */}
            {renderAiImagesList()}

            {/* Pagination */}
            {renderPagination()}
        </div>
    );
};

export default ClientAiImages;
