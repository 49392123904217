import CategoryConfiguration from '@app/containers/pages/AiImageGenerator/types/CategoryConfiguration';
import { RealisticPhotographyCategoryPromptKeys } from '../types';

const configuration: CategoryConfiguration<RealisticPhotographyCategoryPromptKeys> = {
    promptTemplate:
        'A #framing#, #vibe#, #lensAndCamera#, #angle#, taken with #process#, #shootContext# of a #subject# with #lighting#, #time# #usageContext#, #year#',
    inputs: {
        subject: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'input',
            label: 'What is the subject or context of the image?',
            placeholder: 'A white yeti',
            defaultOptions: [],
            initialValue: '',
        },
        framing: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'How is the photo composed? In what framing?',
            placeholder: 'Framing',
            defaultOptions: [
                'close-up',
                'cowboy shot',
                'establishing shot',
                'exposure shot',
                'extreme close-up',
                'extreme long shot',
                'long shot',
                'medium shot',
                'motion blur shot',
                'panoramic shot',
                'zoom shot',
            ].sort(),
            initialValue: 'medium shot',
        },
        vibe: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What is the emotional vibe of the image?',
            placeholder: 'Vibe',
            defaultOptions: [
                'black and white',
                'brooding',
                'dramatic',
                'energetic',
                'happy',
                'intense',
                'melancholic',
                'muted',
                'mysterious',
                'optimistic',
                'serene',
                'somber',
                'stock photo',
                'vibrant',
            ].sort(),
            initialValue: 'stock photo',
        },
        angle: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'How close are we to the subject? What angle?',
            placeholder: 'Angle',
            defaultOptions: [
                'birds-eye view',
                'high-angle',
                'low-angle',
                'medium angle',
                'over-the-shoulder',
                'tilted',
                'worms-eye view',
            ].sort(),
            initialValue: 'high-angle',
        },
        lighting: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'How is the subject lit?Where from? How much light?',
            placeholder: 'Lighting',
            defaultOptions: [
                'artificial lighting',
                'backlighting',
                'bright lighting',
                'butterfly lighting',
                'camera flash',
                'dramatic lighting',
                'low-light',
                'minimal lighting',
                'natural lighting',
                'overhead lighting',
                'rembrandt lighting',
                'semi-silhouette lighting',
                'short lighting',
                'split lighting',
                'under-lighting',
            ].sort(),
            initialValue: 'natural lighting',
        },
        time: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'Artificial or natural light? What time of day?',
            placeholder: 'Lighting',
            defaultOptions: [
                'at night',
                'at sunrise',
                'at sunset',
                'during twilight',
                'in the afternoon',
                'in the evening',
                'in the morning',
                'on the blue hour',
                'on the golden hour',
            ].sort(),
            initialValue: 'in the afternoon',
        },
        lensAndCamera: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What camera lens? Macro, telephoto or wide angle?',
            placeholder: 'Lens & Camera',
            defaultOptions: [
                'fisheye lens',
                'macro lens',
                'standard lens',
                'telephoto lens',
                'tilt-shift lens',
                'ultra-wide-angle lens',
                'wide angle lens',
                'wide-angle lens',
            ].sort(),
            initialValue: 'standard lens',
        },
        shootContext: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'Where is it shot? in the studio or out in the world?',
            placeholder: 'Shoot context',
            defaultOptions: ['indoor shot', 'outdoor shot', 'studio photoshoot'].sort(),
            initialValue: 'indoor shot',
        },
        process: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What film or process is used? Digital or film?',
            placeholder: 'Process',
            defaultOptions: [
                'DSLR camera',
                'Polaroid',
                'action camera',
                'daguerreotype camera',
                'digital camera',
                'film camera',
                'mirrorless camera',
                'smartphone camera',
                'vintage camera',
            ].sort(),
            initialValue: 'digital camera',
        },
        year: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What year was it taken?',
            placeholder: 'Year',
            defaultOptions: ['1800’s', '1900’s', '60’s', '70’s', '80’s', '90’s', '2000’s', 'in the future'],
            initialValue: '2000’s',
        },
        usageContext: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'In what context was this photo ultimately published or used?',
            placeholder: 'Usage context',
            defaultOptions: [
                'for Instagram',
                'for a magazine',
                'for an album',
                'for an exhibit',
                'for blog',
                'for digital',
                'for display',
                'for documentation',
                'for film',
                'for frame',
                'for print',
            ].sort(),
            initialValue: 'for digital',
        },
    },
    getFinalPrompt: (props: RealisticPhotographyCategoryPromptKeys) => {
        return `A ${props.framing.text}, ${props.vibe.text}, ${props.lensAndCamera.text}, ${props.angle.text}, taken with ${props.process.text}, ${props.shootContext.text} of a ${props.subject.text} with ${props.lighting.text}, ${props.time.text} ${props.usageContext.text}, ${props.year.text}`;
    },
};

export default configuration;
