import styled from 'styled-components';
import { Card as DefCard, Colors } from '@blueprintjs/core';

export const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
`;

export const Card = styled(DefCard)`
    display: flex;
    flex-direction: column;
    padding: 0;

    width: 100%;
    height: 95%;
`;

export const DismissModalPanel = styled.div`
    width: 100%;
    height: 5%;
    cursor: pointer;
    background: transparent;

    transition: background 250ms ease;

    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;

export const ViewerWrapper = styled.div`
    display: grid;
    flex-grow: 1;
    align-items: center;

    overflow-y: auto;
    width: 100%;
    background: ${Colors.DARK_GRAY2};
`;

export const ImageViewerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`;
