import FixedWidthPageContainer from '@app/components/FixedWidthPageContainer';
import Flex from '@app/components/Flex';
import Grid from '@app/components/Grid';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { BreadcrumbProps, Button, Card, Elevation, Intent, Spinner, Tooltip } from '@blueprintjs/core';
import {
    AiImageCategory,
    AiImageModel,
    AiImageQuality,
    AiImageStyle,
} from 'dy-frontend-http-repository/lib/modules/AiImage/enums';
import { AiImageResource } from 'dy-frontend-http-repository/lib/modules/AiImage/resources';
import React, { useEffect, useState } from 'react';
import AiModelSelect from './components/AiModelSelect';
import ArtworkGenerator from './components/generators/ArtworkGenerator';
import IllustrationDesignGenerator from './components/generators/IllustrationDesignGenerator';
import RealisticPhotographyGenerator from './components/generators/RealisticPhotographyGenerator';
import ThreeDimensionalArtworkGenerator from './components/generators/ThreeDimensionalArtworkGenerator';
import { aiImageCategoryInformation } from './consts';
import { fetchAiImage, fetchModelSchemas, generateAiImage } from './store/effects';
import { openModal } from '@app/containers/modals/store/events';
import AiImageViewerModal, { AiImageViewerModalProps } from '@app/containers/modals/AiImageViewerModal';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';
import { resetPageBreadcrumbs, setPageBreadcrumbs } from '@app/containers/store/events';
import { usePageTitle } from '@app/hooks';
import AiImage from '@app/containers/components/AiImage';
import { ToastUtils } from '@app/data/utils';

const AiImageGenerator: React.FC = () => {
    usePageTitle('AI Images Generator');
    const navigate = useNavigate();

    const [isGenerating, setIsGenerating] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<AiImageCategory>(AiImageCategory.ART_STYLE);
    const [prompt, setPrompt] = useState<string | null>(null);
    const [model, setModel] = useState<AiImageModel | null>(null);
    const [size, setSize] = useState<string | null>(null);
    const [style, setStyle] = useState<AiImageStyle | null>(null);
    const [quality, setQuality] = useState<AiImageQuality | null>(null);
    const [images, setImages] = useState<AiImageResource[]>([]);

    console.log('--------------');
    console.log('prompt: ', prompt);
    console.log('model: ', model);
    console.log('size: ', size);
    console.log('quality: ', quality);
    console.log('style: ', style);
    console.log('--------------');

    useEffect(() => {
        fetchModelSchemas();
    }, []);

    useEffect(() => {
        const breadcrumbItems: BreadcrumbProps[] = [
            {
                text: 'AI Images',
                onClick: () => navigate(Endpoints.AI_IMAGES),
            },
            { text: 'Generator' },
        ];

        setPageBreadcrumbs(breadcrumbItems);
    }, []);

    useEffect(() => {
        return () => {
            resetPageBreadcrumbs();
        };
    }, []);

    const handleGenerateAiImage = async () => {
        if (!model) {
            console.error('No model selected');
            return;
        }

        if (!prompt) {
            console.error('No prompt selected');
            return;
        }

        if (!quality) {
            console.error('No quality selected');
            return;
        }

        if (!size) {
            console.error('No size selected');
            return;
        }

        if (!style) {
            console.error('No style selected');
            return;
        }

        setIsGenerating(true);
        try {
            const aiImageIdRef = await generateAiImage({
                model: model,
                prompt: prompt,
                quality: quality,
                size: size,
                category: selectedCategory,
                style: style,
            });

            const aiImage = await fetchAiImage(aiImageIdRef.id);
            setImages((prevImages) => [aiImage, ...prevImages]);
        } catch (e) {
            ToastUtils.showToast({ message: 'Something wrong happened', intent: Intent.DANGER });
        } finally {
            setIsGenerating(false);
        }
    };

    const renderCategorySelectStep = () => {
        const handleSelectCategory = (category: AiImageCategory) => {
            if (isGenerating) {
                return;
            }

            setSelectedCategory(category);
        };
        const renderCategoryCard = (category: AiImageCategory) => {
            const data = aiImageCategoryInformation[category];
            if (!data) {
                return null;
            }

            const isSelected = selectedCategory === category;

            return (
                <Card interactive={!isGenerating || isSelected} selected={isSelected} elevation={Elevation.ONE}>
                    <Heading type="h4" className="mb-1">
                        {data.name}
                    </Heading>
                    <DevText muted>{data.description}</DevText>
                </Card>
            );
        };

        return (
            <div>
                <Grid container>
                    {(Object.keys(aiImageCategoryInformation) as AiImageCategory[]).map((category) => (
                        <Grid key={category} className="mb-2" lg={3} onClick={() => handleSelectCategory(category)}>
                            {renderCategoryCard(category)}
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const renderCategoryGenerator = () => {
        if (!selectedCategory) {
            // No category selected yet
            return null;
        }

        // Get common generator props
        const commonGeneratorProps = {
            isGenerating: isGenerating,
            onPromptChange: setPrompt,
        };

        // Get generator based on selected category
        let generator;
        switch (selectedCategory) {
            case AiImageCategory.ART_STYLE:
                generator = <ArtworkGenerator {...commonGeneratorProps} />;
                break;
            case AiImageCategory.ILLUSTRATION:
                generator = <IllustrationDesignGenerator {...commonGeneratorProps} />;
                break;
            case AiImageCategory.PHOTO:
                generator = <RealisticPhotographyGenerator {...commonGeneratorProps} />;
                break;
            case AiImageCategory.THREE_DIMENSIONAL:
                generator = <ThreeDimensionalArtworkGenerator {...commonGeneratorProps} />;
                break;
        }

        return <Card>{generator}</Card>;
    };

    const renderImageLoader = (withCustomSize: boolean) => {
        return (
            <Flex
                fullWidth
                fullHeight
                direction="column"
                align="center"
                justify="center"
                style={{
                    minWidth: withCustomSize ? '300px' : undefined,
                    minHeight: withCustomSize ? '300px' : undefined,
                }}
            >
                <Spinner className="mb-2" size={100} />
                <DevText muted>Your image is being generated, please, wait</DevText>
            </Flex>
        );
    };

    const renderGeneratedAiImages = () => {
        if (images.length === 0 && !isGenerating) {
            return null;
        }

        const handleOpenImageViewer = (index: number) => {
            openModal<AiImageViewerModalProps>({
                ModalComponent: AiImageViewerModal,
                data: {
                    currentFileIndex: index,
                    images: images,
                },
            });
        };

        return (
            <Grid className="mt-2" container>
                {isGenerating && <Grid lg={3}>{renderImageLoader(images.length === 0)}</Grid>}
                {images.map((image, index) => (
                    <Grid key={image.id} lg={3} onClick={() => handleOpenImageViewer(index)}>
                        <AiImage image={image} />
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <FixedWidthPageContainer>
            <Heading className="mb-2" type="h3">
                Generator
            </Heading>

            {renderCategorySelectStep()}
            {renderCategoryGenerator()}

            <Card className="mt-2">
                <AiModelSelect
                    isGenerating={isGenerating}
                    onQualityChange={setQuality}
                    onSizeChange={setSize}
                    onStyleChange={setStyle}
                    onModelChange={setModel}
                />
            </Card>

            <Flex className="mt-2" direction="row" justify="flex-end">
                <Button
                    loading={isGenerating}
                    disabled={model === null || prompt === null}
                    intent={Intent.PRIMARY}
                    onClick={handleGenerateAiImage}
                >
                    Generate
                </Button>
            </Flex>

            {renderGeneratedAiImages()}
        </FixedWidthPageContainer>
    );
};

export default AiImageGenerator;
