import { AiImageResource } from 'dy-frontend-http-repository/lib/modules/AiImage/resources';
import React, { HTMLAttributes } from 'react';
import Image from '@components/Image';
import { ImagePositioner, ImageWrapper } from './styled';
import { FileHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/FileHashPreview/enums';
import { fileHashPreview } from '@app/data/consts';

export interface AiImageProps {
    image: AiImageResource;
}
export type Props = HTMLAttributes<HTMLDivElement> & AiImageProps;

const AiImage: React.FC<Props> = ({ image, ...props }) => {
    // Get preview url
    let previewUrl: string | null = image.file.url;
    if (image.file.preview_path) {
        previewUrl = fileHashPreview.getPreviewUrl(image.file.preview_path, FileHashPreviewSize.MD);
    }

    return (
        <ImagePositioner $overlayText={image.prompt} {...props}>
            <ImageWrapper>
                <Image src={previewUrl} width="100%" height="100%" objectFit="cover" />
            </ImageWrapper>
        </ImagePositioner>
    );
};

export default AiImage;
