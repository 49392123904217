import DevText from '@app/components/Text';
import { Tooltip } from '@blueprintjs/core';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';

export interface LastOnlineTagProps {
    latestPresenceAt: string;
    recentLabel?: string;
    recentPointInMinutes?: number;
}
export type Props = HTMLAttributes<HTMLDivElement> & LastOnlineTagProps;

const LastOnlineTag: React.FC<Props> = ({
    latestPresenceAt,
    recentLabel = 'Recent',
    recentPointInMinutes = 10,
    ...props
}) => {
    let lastOnlineLabel = 'Unknown';
    const latestPresenceMoment = moment(latestPresenceAt);

    // Get label
    if (moment().diff(latestPresenceMoment, 'minutes') < recentPointInMinutes) {
        lastOnlineLabel = 'Recently';
    } else {
        lastOnlineLabel = TextFormatUtils.capitalize(latestPresenceMoment.fromNow());
    }

    return (
        <div {...props}>
            <Tooltip content={latestPresenceMoment.format('D MMM YYYY, HH:mm')}>
                <DevText>{lastOnlineLabel}</DevText>
            </Tooltip>
        </div>
    );
};

export default LastOnlineTag;
