import CategoryConfiguration from '@app/containers/pages/AiImageGenerator/types/CategoryConfiguration';
import { ThreeDimensionalArtworkCategoryPromptKeys } from '../types';

const configuration: CategoryConfiguration<ThreeDimensionalArtworkCategoryPromptKeys> = {
    promptTemplate: 'A 3d renderer of #subject#, #texture#, #lighting#, #color#',
    inputs: {
        subject: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'input',
            label: 'What is the subject of the 3D artwork?',
            placeholder: 'A white yeti',
            defaultOptions: [],
            initialValue: '',
        },
        texture: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What is the texture of the model? What material?',
            placeholder: 'Texture',
            defaultOptions: [
                'aluminum',
                'chrome',
                'glass',
                'gloss',
                'golden',
                'marble',
                'matte',
                'metallic',
                'organic',
                'origami',
                'realistic',
                'silver',
                'stone',
                'synthetic',
                'wooden',
            ].sort(),
            initialValue: 'realistic',
        },
        lighting: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'How is the subject lit? Where from? How much light?',
            placeholder: 'Light',
            defaultOptions: [
                'artificial lighting',
                'backlighting',
                'bright lighting',
                'butterfly lighting',
                'camera flash',
                'dramatic lighting',
                'low-light',
                'minimal lighting',
                'natural lighting',
                'overhead lighting',
                'rembrandt lighting',
                'semi-silhouette lighting',
                'short lighting',
                'split lighting',
                'studio lighting',
                'under-lighting',
            ].sort(),
            initialValue: 'natural lighting',
        },
        color: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'How much color? How bright and dark is the image?',
            placeholder: 'Color',
            defaultOptions: [
                'black and white',
                'bright',
                'colorful',
                'dark',
                'monochromatic',
                'neon',
                'soft',
                'vibrant',
            ].sort(),
            initialValue: 'bright',
        },
    },
    getFinalPrompt: (props: ThreeDimensionalArtworkCategoryPromptKeys) => {
        return `A 3d renderer of ${props.subject.text}, ${props.texture.text}, ${props.lighting.text}, ${props.color.text}`;
    },
};

export default configuration;
