import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { TaskListItemResource, TaskResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { TaskFilterInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import {
    HydratedFilterResource,
    ClientUserResource,
    CoreUserResource,
    WorkspaceResource,
    StaticFilterDataResource,
} from 'dy-frontend-http-repository/lib/modules/Filter/resources';
import {
    HydrateFilterDataFilterInput,
    ClientUserFilterDataFilterInput,
    CoreUserFilterDataFilterInput,
    WorkspaceFilterDataFilterInput,
    StaticDataFilterInput,
} from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import domain from './domain';

// Fetch task
export const fetchTask = domain.createEffect<ID, TaskResource>({
    name: 'fetchTask',
    handler: async (id) => {
        return await repository.task().getById(id);
    },
});

// Fetch tasks
export const fetchTasks = domain.createEffect<
    { pagination: OffsetPaginationInput; filter?: TaskFilterInput },
    CollectionResource<TaskListItemResource, {}>
>({
    name: 'fetchTasks',
    handler: async ({ pagination, filter }) => {
        return await repository.task().get(pagination, filter);
    },
});

// Hydrate query parameters
export const hydrateQueryParameters = domain.createEffect<HydrateFilterDataFilterInput, HydratedFilterResource>({
    name: 'hydrateQueryParameters',
    handler: async (input) => {
        return await repository.filter().hydrateData(input);
    },
});

// Fetch client user data
export const fetchClientUserData = domain.createEffect<
    ClientUserFilterDataFilterInput,
    CollectionResource<ClientUserResource, {}>
>({
    name: 'fetchClientUserData',
    handler: async (input) => {
        return await repository.filter().getClientUserData(input);
    },
});

// Fetch core user data
export const fetchCoreUserData = domain.createEffect<
    CoreUserFilterDataFilterInput,
    CollectionResource<CoreUserResource, {}>
>({
    name: 'fetchCoreUserData',
    handler: async (input) => {
        return await repository.filter().getCoreUserData(input);
    },
});

// Fetch plan data
export const fetchStaticData = domain.createEffect<
    StaticDataFilterInput,
    StaticFilterDataResource
>({
    name: 'fetchStaticData',
    handler: async (filter) => {
        return await repository.filter().getStaticData(filter);
    },
});

// Fetch workspace data
export const fetchWorkspaceData = domain.createEffect<
    WorkspaceFilterDataFilterInput,
    CollectionResource<WorkspaceResource, {}>
>({
    name: 'fetchWorkspaceData',
    handler: async (input) => {
        return await repository.filter().getWorkspaceData(input);
    },
});
