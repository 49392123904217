import { AiImageModel } from 'dy-frontend-http-repository/lib/modules/AiImage/enums';

const aiImageModelInformation: {
    [model in AiImageModel]: {
        name: string;
    };
} = {
    [AiImageModel.DALLE_2]: {
        name: 'dalle 2',
    },
    [AiImageModel.DALLE_3]: {
        name: 'dalle 3',
    },
};

export default aiImageModelInformation;
