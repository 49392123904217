import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { AiImageFilterInput } from 'dy-frontend-http-repository/lib/modules/AiImage/inputs';
import { AiImageResource } from 'dy-frontend-http-repository/lib/modules/AiImage/resources';
import domain from './domain';

// Fetch AI images
export const fetchAiImages = domain.createEffect<
    { pagination: OffsetPaginationInput; filter?: AiImageFilterInput },
    CollectionResource<AiImageResource, {}>
>({
    name: 'fetchAiImages',
    handler: async ({ pagination, filter }) => {
        return await repository.aiImage().getAiImageList(pagination, filter);
    },
});

// Fetch AI bookmarked images
export const fetchAiBookmarkedImages = domain.createEffect<
    { pagination: OffsetPaginationInput; filter?: AiImageFilterInput },
    CollectionResource<AiImageResource, {}>
>({
    name: 'fetchAiBookmarkedImages',
    handler: async ({ pagination, filter }) => {
        return await repository.aiImage().getAiBookmarkedImageList(pagination, filter);
    },
});
