import PlanTag from '@app/containers/components/PlanTag';
import { usePageTitle, useScrollToTop } from '@app/hooks';
import { Button, Colors, Icon, Intent, MenuItem, Spinner, Tag, Tooltip } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import Pagination from '@components/Pagination';
import RouterLink from '@components/RouterLink';
import Table from '@components/Table';
import TableCell from '@components/TableCell';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview } from '@data/consts';
import { SessionStorage } from '@data/enums';
import { openModal } from '@modals/store/events';
import { ClientUserFilterInput } from 'dy-frontend-http-repository/lib/modules/ClientUser/inputs';
import {
    ClientUserListItemResource,
    ClientUserMetadataResource,
} from 'dy-frontend-http-repository/lib/modules/ClientUser/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlatformClientUserFilter from './components/PlatformClientUserFilter';
import { amountOfClientsOnPage, clientUserSortItemsInformation, initialPlatformClientUserFilterData } from './consts';
import CreateClientModal from './modals/CreateClientModal';
import { fetchClients, hydrateQueryParameters } from './store/effects';
import {
    resetClients,
    resetClientUserSortData,
    resetIsQueryHydrationFinished,
    resetPage,
    resetPlatformClientUserFilterData,
    setClientUserSortData,
    setIsQueryHydrationFinished,
    setPage,
    setPlatformClientUserFilterData,
} from './store/events';
import {
    $clients,
    $clientUserSortData,
    $isQueryHydrationFinished,
    $page,
    $platformClientUserFilterData,
} from './store/states';
import { ClientUserFilterQueryParameters, PlatformClientUserFilterData } from './types';
import { PlatformClientUserFilter as PlatformClientUserFilterValueObject } from './valueObjects';
import { $permissions } from '@containers/store/states';
import { ClientUserPermission } from 'dy-frontend-permissions/lib/permission';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType, LicenceState, SortDirection } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse, SortData } from 'dy-frontend-http-repository/lib/data/types';
import LicenceTag from '@app/containers/components/LicenceTag';
import { ClientUserSortField } from './enums';
import { ItemRendererProps } from '@blueprintjs/select';
import Select from '@app/components/Select';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import LastOnlineTag from '@app/containers/components/LastOnlineTag';

const Clients: React.FC = () => {
    usePageTitle('Clients');
    const { scrollToTop } = useScrollToTop();

    const permissions = useStore($permissions);

    const navigate = useNavigate();
    const location = useLocation();
    const prevPage = useRef<number>(1);

    const page = useStore($page);
    const clients = useStore($clients);
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformClientUserFilterData = useStore($platformClientUserFilterData);
    const isFetchingClients = useStore(fetchClients.pending);
    const clientUserSortData = useStore($clientUserSortData);

    const handleLoadPage = async (
        newPage: number,
        sortData: SortData<ClientUserSortField>,
        filter?: ClientUserFilterInput
    ) => {
        const pageOffset = (newPage - 1) * amountOfClientsOnPage;

        try {
            await fetchClients({
                pagination: {
                    _pagination: { limit: amountOfClientsOnPage, offset: pageOffset },
                    _sort: sortData,
                },
                filter: {
                    is_shutdown: '0',
                    is_verified: '1',
                    ...filter,
                },
            });

            prevPage.current = newPage;
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Clients were not found`,
                    intent: Intent.DANGER,
                });

                // Go to tasks page
                navigate(Endpoints.TASKS);
            }
        }
    };

    // Initial load, parse query string If exists and apply filter, pagination, order and range
    useEffect(() => {
        let urlQueryParameters = location.search.slice(1);

        if (urlQueryParameters.length === 0) {
            // Filter
            const filterQueryParameters = sessionStorage.getItem(SessionStorage.CLIENT_USER_FILTER);
            if (filterQueryParameters && filterQueryParameters.length > 0) {
                urlQueryParameters = filterQueryParameters;
            }

            // Sort parameters
            const sortParameters = sessionStorage.getItem(SessionStorage.CLIENT_USER_SORTING);
            if (sortParameters) {
                if (urlQueryParameters.length > 0) {
                    urlQueryParameters += `&${sortParameters}`;
                } else {
                    urlQueryParameters = sortParameters;
                }
            }
        }

        // Get initial sort data
        const allQueryParameters = qs.parse(urlQueryParameters);
        let initialSortData: SortData<ClientUserSortField> = {
            field: ClientUserSortField.VERIFIED_AT,
            direction: SortDirection.DESC,
        };
        if (allQueryParameters.field) {
            initialSortData.field = allQueryParameters.field as ClientUserSortField;
            initialSortData.direction = allQueryParameters.direction as SortDirection;
        }

        const handleHydrateQueryParameters = async () => {
            // Get query params, get hydration out of it and create initial filter data
            const queryParameters: ClientUserFilterQueryParameters = allQueryParameters;
            const hydrationInput = PlatformClientUserFilterValueObject.queryToHydrationInput(queryParameters);
            const platformClientUserFilterData: PlatformClientUserFilterData = {
                query: '',
                isArchived: queryParameters.isArchived !== undefined ? Boolean(queryParameters.isArchived) : null,
                isVerified: queryParameters.isVerified !== undefined ? Boolean(queryParameters.isVerified) : null,
                plans: [],
            };

            try {
                // Hydrate & update filter data
                const { plan } = await hydrateQueryParameters(hydrationInput);
                platformClientUserFilterData.plans = plan ?? [];
            } finally {
                // Update page, filter data and update hydration completion flag
                const page = queryParameters.page ? parseInt(queryParameters.page) : 1;
                prevPage.current = page;
                setPage(page);
                setClientUserSortData(initialSortData);
                setPlatformClientUserFilterData(platformClientUserFilterData);
                setIsQueryHydrationFinished(true);
            }
        };

        handleHydrateQueryParameters();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            if (page !== 1 && page === prevPage.current) {
                // Filter updated, so we need to go to first page
                setPage(1);
                return;
            }

            // platform filter -> API filter input
            const clientUsersFilterInput =
                PlatformClientUserFilterValueObject.platformFilterToAPIFilterInput(platformClientUserFilterData);

            // API filter input -> query params
            const clientUserFilterQueryParametersString = qs.stringify(
                PlatformClientUserFilterValueObject.platformFilterToQueryParameters(page, platformClientUserFilterData)
            );

            // Get sort data query parameters
            const clientUserSortDataQueryParametersString = qs.stringify(clientUserSortData);

            // Update session storage & URL
            sessionStorage.setItem(SessionStorage.CLIENT_USER_FILTER, clientUserFilterQueryParametersString);
            sessionStorage.setItem(SessionStorage.CLIENT_USER_SORTING, clientUserSortDataQueryParametersString);
            navigate(
                `${Endpoints.CLIENTS}?${clientUserFilterQueryParametersString}&${clientUserSortDataQueryParametersString}`,
                {
                    replace: true,
                }
            );

            // Load page & scroll to top to see first items in the list
            handleLoadPage(page, clientUserSortData, clientUsersFilterInput);
            scrollToTop();
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished, page, platformClientUserFilterData, clientUserSortData]);

    useEffect(() => {
        return () => {
            resetClients();
            resetPage();
            resetIsQueryHydrationFinished();
            resetPlatformClientUserFilterData();
            resetClientUserSortData();
        };
    }, []);

    if (!clients) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderClientUserSortingSelect = () => {
        const currentLabel =
            clientUserSortItemsInformation[`${clientUserSortData.field}${clientUserSortData.direction}`] ??
            `${clientUserSortData.field} - ${clientUserSortData.direction}`;

        const renderItem = (item: SortData<ClientUserSortField>, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive =
                item.direction === clientUserSortData.direction && item.field === clientUserSortData.field;
            const label =
                clientUserSortItemsInformation[`${item.field}${item.direction}`] ?? `${item.field} - ${item.direction}`;
            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={`${item.field}${item.direction}`}
                    text={label}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: SortData<ClientUserSortField>) => {
            setClientUserSortData(item);
        };

        const getItems = (): SortData<ClientUserSortField>[] => {
            return [
                {
                    field: ClientUserSortField.NAME,
                    direction: SortDirection.ASC,
                },
                {
                    field: ClientUserSortField.NAME,
                    direction: SortDirection.DESC,
                },
                {
                    field: ClientUserSortField.LATEST_ACTIVITY_AT,
                    direction: SortDirection.DESC,
                },
                {
                    field: ClientUserSortField.LATEST_ACTIVITY_AT,
                    direction: SortDirection.ASC,
                },
                {
                    field: ClientUserSortField.VERIFIED_AT,
                    direction: SortDirection.DESC,
                },
                {
                    field: ClientUserSortField.VERIFIED_AT,
                    direction: SortDirection.ASC,
                },
            ];
        };

        return (
            <Select<SortData<ClientUserSortField>>
                items={getItems()}
                itemRenderer={renderItem}
                onItemSelect={handleItemSelect}
                popoverProps={{
                    matchTargetWidth: true,
                    usePortal: false,
                    className: 'ml-auto',
                }}
                selectButtonProps={{
                    rightIcon: 'double-caret-vertical',
                    icon: 'sort',
                    text: currentLabel,
                    style: { width: '250px' },
                }}
            />
        );
    };

    const renderHeader = () => {
        const renderAddButton = () => {
            if (!permissions.isRoot.clientUser && !permissions.has(ClientUserPermission.CREATE)) {
                return null;
            }

            return (
                <Button
                    className="ml-1"
                    icon="add"
                    intent={Intent.PRIMARY}
                    onClick={() => openModal({ ModalComponent: CreateClientModal })}
                >
                    Add client
                </Button>
            );
        };

        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Clients</Heading>

                <Flex direction="row" align="center">
                    {renderClientUserSortingSelect()}
                    {renderAddButton()}
                </Flex>
            </Flex>
        );
    };

    const renderClientsTable = () => {
        if (clients.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No client users were found
                        </Heading>
                    }
                />
            );
        }

        const renderTableHeader = () => {
            return (
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Email</th>
                        <th>Plans</th>
                        <th>Last online</th>
                    </tr>
                </thead>
            );
        };

        const renderTableBody = () => {
            const getCompanyInformation = (client: ClientUserListItemResource) => {
                let companyInformation = '';

                if (client.company_name.trim().length > 0) {
                    companyInformation = client.company_name;

                    if (client.company_position.trim().length > 0) {
                        companyInformation = `${client.company_position} at ${client.company_name}`;
                    }
                }

                return companyInformation;
            };

            const renderClientInformation = (client: ClientUserListItemResource) => {
                const renderClientCompanyInformation = () => {
                    const companyInformation = getCompanyInformation(client);
                    if (companyInformation.length === 0) {
                        return null;
                    }

                    return <DevText muted>{companyInformation}</DevText>;
                };

                let avatarSrc: string | null = null;
                if (client.image_hash) {
                    avatarSrc = imageHashPreview.userImage(client.image_hash, ImageHashPreviewSize.SM);
                }

                return (
                    <Flex direction="row" align="center">
                        <Avatar className="mr-1" alt={client.first_name} src={avatarSrc} width="42px" height="42px" />
                        <Flex direction="column" justify="center">
                            <RouterLink
                                className="mr-1"
                                color={Colors.WHITE}
                                to={Endpoints.CLIENT_TASKS.replace(':clientId', client.id)}
                            >
                                {client.first_name} {client.last_name}
                            </RouterLink>

                            {renderClientCompanyInformation()}
                        </Flex>
                    </Flex>
                );
            };

            const renderClientPlansInformation = (client: ClientUserListItemResource) => {
                if (client.licences.length === 0) {
                    // No licences, meaning there are no plans attached for this client
                    return <DevText muted>No plans</DevText>;
                }

                return (
                    <>
                        {client.licences.map((licence) => {
                            const plan = licence.plan;

                            return (
                                <LicenceTag
                                    suspended={licence.state === LicenceState.SUSPENDED}
                                    className="mr-small mb-small"
                                    key={licence.id}
                                    name={plan.title}
                                    color={plan.color}
                                    quantity={licence.quantity}
                                >
                                    {plan.title}
                                </LicenceTag>
                            );
                        })}
                    </>
                );
            };

            const renderRow = (client: ClientUserListItemResource) => {
                return (
                    <tr key={client.id}>
                        <TableCell verticalAlign="middle">{renderClientInformation(client)}</TableCell>
                        <TableCell verticalAlign="middle">
                            <DevText>{client.email}</DevText>
                            {client.verified_at === null && (
                                <Tag minimal className="mt-small">
                                    Unverified
                                </Tag>
                            )}
                        </TableCell>
                        <TableCell verticalAlign="middle">{renderClientPlansInformation(client)}</TableCell>
                        <TableCell verticalAlign="middle">
                            <LastOnlineTag latestPresenceAt={client.metadata.latest_presence_at} />
                        </TableCell>
                    </tr>
                );
            };

            return <tbody>{clients.items.map(renderRow)}</tbody>;
        };

        return (
            <Table striped loading={isFetchingClients}>
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderPagination = () => {
        // Admins were not fetched yet
        if (clients === null) {
            return null;
        }

        // Check if offset paginator exist
        if (clients.paginator === null) {
            return;
        }

        // Only one page exist
        if (!clients.paginator.has_more && clients.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingClients}
                    hasMore={clients.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfClientsOnPage}
                    totalItems={clients.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            {/* Header */}
            {renderHeader()}

            {/* Filter */}
            <PlatformClientUserFilter className="mb-2" />

            {/* Clients table */}
            {renderClientsTable()}

            {/* Pagination */}
            {renderPagination()}
        </FixedWidthPageContainer>
    );
};

export default Clients;
