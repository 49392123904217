import React from 'react';
import { CategoryPromptKeysProps } from '../../types';
import { Overview, Label } from './styled';

interface PromptOverviewProps {
    template: string;
    categoryPromptConfiguration: CategoryPromptKeysProps;
}

type Props = PromptOverviewProps;

const PromptOverview: React.FC<Props> = ({ template, categoryPromptConfiguration }) => {
    const renderOverview = () => {
        return template.split('#').map((key) => {
            const promptParameterConfig = categoryPromptConfiguration[key];

            if (promptParameterConfig) {
                if (promptParameterConfig.text.length === 0) {
                    return;
                }

                if (promptParameterConfig.isActive) {
                    return <Label $isActive={true}>{promptParameterConfig.text}</Label>;
                }

                return (
                    <Label $isActive={false} key={promptParameterConfig.key}>
                        {promptParameterConfig.text}
                    </Label>
                );
            }

            return key;
        });
    };

    return <Overview>{renderOverview()}</Overview>;
};

export default PromptOverview;
