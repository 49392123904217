import FixedWidthPageContainer from '@app/components/FixedWidthPageContainer';
import Flex from '@app/components/Flex';
import Grid from '@app/components/Grid';
import Heading from '@app/components/Heading';
import Pagination from '@app/components/Pagination';
import { Endpoints } from '@app/data/consts';
import { usePageTitle, useScrollToTop } from '@app/hooks';
import { Button, HTMLSelect, Icon, Intent, Spinner } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { amountOfAiImagesOnPage } from './consts';
import { fetchAiBookmarkedImages, fetchAiImages } from './store/effects';
import { $aiImages } from './store/states';
import NonIdealState from '@app/components/NonIdealState';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType, UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { ToastUtils } from '@app/data/utils';
import { openModal } from '@app/containers/modals/store/events';
import AiImageViewerModal, { AiImageViewerModalProps } from '@app/containers/modals/AiImageViewerModal';
import AiImage from '@app/containers/components/AiImage';
import { resetAiImages } from './store/events';
import { AiImageFilterType } from './enums';
import { AiImageFilterInput } from 'dy-frontend-http-repository/lib/modules/AiImage/inputs';

const AiImages: React.FC = () => {
    usePageTitle('AI Images');
    const navigate = useNavigate();
    const { scrollToTop } = useScrollToTop();

    const aiImages = useStore($aiImages);
    const isFetchingAiImages = useStore(fetchAiImages.pending || fetchAiBookmarkedImages.pending);

    const [page, setPage] = useState(1);
    const [isInitialized, setIsInitialized] = useState(false);
    const [filter, setFilter] = useState<AiImageFilterType>(AiImageFilterType.ALL);

    const handleLoadPage = async (newPage: number) => {
        if (!isInitialized) {
            setIsInitialized(true);
        }

        const pageOffset = (newPage - 1) * amountOfAiImagesOnPage;

        try {
            if (filter === AiImageFilterType.BOOKMARKED) {
                await fetchAiBookmarkedImages({
                    pagination: {
                        _pagination: { limit: amountOfAiImagesOnPage, offset: pageOffset },
                    },
                });
            } else {
                const filterInput: AiImageFilterInput = {};
                if (filter === AiImageFilterType.INTERNAL_USER) {
                    filterInput.user_type = UserType.CORE;
                }

                await fetchAiImages({
                    pagination: {
                        _pagination: { limit: amountOfAiImagesOnPage, offset: pageOffset },
                    },
                    filter: filterInput,
                });
            }
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `AI images were not found`,
                    intent: Intent.DANGER,
                });

                // Go to client tasks page
                navigate(Endpoints.TASKS);
            }
        }
    };

    useEffect(() => {
        if (!isInitialized) {
            return;
        }

        if (page !== 1) {
            setPage(1);
        } else {
            handleLoadPage(page);
            scrollToTop();
        }
    }, [filter]);

    useEffect(() => {
        handleLoadPage(page);
        scrollToTop();
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        return () => {
            resetAiImages();
        };
    }, []);

    const renderFilterSelect = () => {
        return (
            <HTMLSelect className="mr-1" onChange={(e) => setFilter(e.currentTarget.value as AiImageFilterType)}>
                <option value={AiImageFilterType.ALL}>All images</option>
                <option value={AiImageFilterType.INTERNAL_USER}>Do not show client images</option>
                <option value={AiImageFilterType.BOOKMARKED}>My bookmarks</option>
            </HTMLSelect>
        );
    };

    const renderAiImages = () => {
        // AI images were NOT fetched yet
        if (!aiImages) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // AI images were fetched, but where are no ai images exist
        if (aiImages.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No ai images were found
                        </Heading>
                    }
                />
            );
        }

        return (
            <Grid container>
                {aiImages.items.map((aiImage, index) => {
                    return (
                        <Grid
                            className="mb-2"
                            key={aiImage.id}
                            lg={3}
                            onClick={() =>
                                openModal<AiImageViewerModalProps>({
                                    ModalComponent: AiImageViewerModal,
                                    data: {
                                        currentFileIndex: index,
                                        images: aiImages.items,
                                    },
                                })
                            }
                        >
                            <AiImage image={aiImage} />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const renderPagination = () => {
        // Client brand profiles is not fetched yet
        if (aiImages === null) {
            return null;
        }

        // Check if offset paginator exist
        if (aiImages.paginator === null) {
            return;
        }

        // Only one page exist
        if (!aiImages.paginator.has_more && aiImages.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingAiImages}
                    hasMore={aiImages.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfAiImagesOnPage}
                    totalItems={aiImages.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            <Flex className="mb-2" direction="row" justify="space-between">
                <Heading type="h3">AI Images</Heading>

                <Flex direction="row" align="center">
                    {renderFilterSelect()}
                    <Button intent={Intent.PRIMARY} onClick={() => navigate(Endpoints.AI_IMAGE_GENERATOR)}>
                        Generate new image
                    </Button>
                </Flex>
            </Flex>

            {renderAiImages()}
            {renderPagination()}
        </FixedWidthPageContainer>
    );
};

export default AiImages;
