import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { GenerateAiImageInput } from 'dy-frontend-http-repository/lib/modules/AiImage/inputs';
import { AiImageRef } from 'dy-frontend-http-repository/lib/modules/AiImage/refs';
import { AiImageResource, ModelSchemaResource } from 'dy-frontend-http-repository/lib/modules/AiImage/resources';
import repository from 'dy-frontend-http-repository/lib/repository';
import domain from './domain';

// Fetch model schemas
export const fetchModelSchemas = domain.createEffect<void, CollectionResource<ModelSchemaResource, {}>>({
    name: 'fetchModelSchemas',
    handler: async () => {
        return await repository.aiImage().getModelSchemaList();
    },
});

// Generate AI image
export const generateAiImage = domain.createEffect<GenerateAiImageInput, AiImageRef>({
    name: 'generateAiImage',
    handler: async (input) => {
        return await repository.aiImage().generate(input);
    },
});

// Fetch AI image
export const fetchAiImage = domain.createEffect<ID, AiImageResource>({
    name: 'fetchAiImage',
    handler: async (id) => {
        return await repository.aiImage().getById(id);
    },
});
