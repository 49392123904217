import * as Sentry from '@sentry/react';
import {
    fetchAuthorizedUser,
    fetchAuthorizedUserPermissions,
    fetchWorkspaceInitialDataByWorkspaceKey,
} from './effects';
import { setPageBreadcrumbs, toggleDevModeFlag } from './events';
import {
    $authorizedUser,
    $isAuthorized,
    $isDevModeEnabled,
    $pageBreadcrumbs,
    $permissions,
    $workspaceInitData,
} from './states';

import '@modals/store/init';
import '@pages/AiImageGenerator/store/init';
import '@pages/AiImages/store/init';
import '@pages/Authentication/store/init';
import '@pages/Tasks/store/init';
import '@pages/Clients/store/init';
import '@pages/Client/store/init';
import '@pages/CoreUsers/store/init';
import '@pages/CoreUser/store/init';
import '@pages/Plan/store/init';
import '@pages/Plans/store/init';
import '@pages/Subscription/store/init';
import '@pages/BrandProfile/store/init';
import '@pages/Task/store/init';
import '@pages/TaskMessageTemplates/store/init';
import '@pages/TaskCategories/store/init';
import '@pages/TaskCategory/store/init';
import '@pages/TaskCategoryGroups/store/init';
import '@pages/TaskCategoryFeaturedBlocks/store/init';
import '@pages/TaskClientFeedback/store/init';
import '@pages/TaskKanbanBoard/store/init';
import '@pages/Task';
import '@pages/Team/store/init';
import '@pages/Teams/store/init';
import '@pages/Workspaces/store/init';
import '@pages/Workspace/store/init';
import '@pages/WorkspaceUser/store/init';
import '@pages/WorkspaceSignup/store/init';
import '@containers/components/UserSettings/store/init';
import PermissionContainer from '@app/lib/PermissionContainer';

// Fetch authorized user
$isAuthorized.on(fetchAuthorizedUser.doneData, () => {
    return true;
});

$authorizedUser.on(fetchAuthorizedUser.doneData, (_, authorizedUser) => {
    const user = authorizedUser.user;

    Sentry.setUser({
        id: user.id,
        email: user.email,
        username: `${user.first_name} ${user.last_name}`,
    });

    return authorizedUser;
});

$permissions.on(
    fetchAuthorizedUserPermissions.doneData,
    (_, container) => new PermissionContainer(container.list),
);

// Fetch workspace init data by workspace key
$workspaceInitData.on(fetchWorkspaceInitialDataByWorkspaceKey.doneData, (_, data) => data);

// Toggle dev mode flag
$isDevModeEnabled.on(toggleDevModeFlag, (prev) => !prev);

// Set page tab items
$pageBreadcrumbs.on(setPageBreadcrumbs, (_, pageBreadcrumbs) => pageBreadcrumbs);
