import { AiImageQuality } from 'dy-frontend-http-repository/lib/modules/AiImage/enums';

const aiImageQualityInformation: {
    [model in AiImageQuality]: {
        name: string;
    };
} = {
    [AiImageQuality.HD]: {
        name: 'HD',
    },
    [AiImageQuality.STANDARD]: {
        name: 'standard',
    },
};

export default aiImageQualityInformation;
