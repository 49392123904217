import React, { HTMLAttributes } from 'react';
import { $workspaceInitData } from '@containers/store/states';
import MenuHeader from '@containers/components/Layout/components/NextMenu/components/MenuHeader';
import { useStore } from 'effector-react';
import { Wrapper } from '@containers/components/Layout/components/NextMenu/styled';
import DotYetiMenuItems from '@app/containers/components/Layout/components/NextMenu/components/variants/DotYetiMenuItems';
import WorkspaceMenuItems from '@app/containers/components/Layout/components/NextMenu/components/variants/WorkspaceMenuItems';

export type Props = HTMLAttributes<HTMLDivElement>;

const NextMenu: React.FC<Props> = ({ ...props }) => {
    const workspace = useStore($workspaceInitData);

    const renderHeaderBlock = () => {
        if (workspace) {
            return (
                <MenuHeader
                    workspaceId={workspace.workspace.id}
                    title={workspace.workspace.title}
                    subtitle={`#${workspace.workspace.id}`}
                    className="mb-3"
                />
            );
        }

        return <MenuHeader workspaceId={null} title="DotYeti" subtitle="team.dotyeti.com" className="mb-3" />;
    };

    const renderMenuItems = () => {
        return workspace ? <WorkspaceMenuItems /> : <DotYetiMenuItems />;
    };

    return (
        <Wrapper className="custom-thin-scroll" {...props}>
            {renderHeaderBlock()}
            {renderMenuItems()}
        </Wrapper>
    );
};

export default NextMenu;
