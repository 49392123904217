import Box from '@app/components/Box';
import DropzoneArea from '@app/components/DropzoneArea';
import FilePreview from '@app/components/FilePreview';
import DevText from '@app/components/Text';
import { rootTaskDeliverablesPathLabel } from '@app/containers/pages/Task/consts';
import { taskDeliverablesTreeApi } from '@pages/Task/store/apis';
import { $taskDeliverablesTree } from '@app/containers/pages/Task/store/states';
import { $authorizedUser } from '@app/containers/store/states';
import { useUpload } from '@app/hooks';
import { getFileMaxSizeValidator } from '@app/hooks/validation/functions';
import {
    Breadcrumb,
    BreadcrumbProps,
    Breadcrumbs,
    Button,
    Callout,
    Card,
    Divider,
    Elevation,
    Icon,
    InputGroup,
    Intent,
    Popover,
    Tag,
    Tooltip,
} from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { FilePurpose, TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { VerifiedFileResource } from 'dy-frontend-http-repository/lib/modules/File/resources';
import { CreateTaskDeliverablesInput } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/inputs';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { FileUtils, TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { createTaskDeliverablesBatch } from '../../../../store/effects';
import { $currentPathLocation } from '../../../../store/states';

/**
 * Switch "replace deliverable"
 * If true, then user can't update the file name
 * If false, then user can update the file name
 *
 * Functions to create
 * getFileData - by path & key
 * checkTaskDeliverableCollisions
 * checkFileMapCollisionsAtPath
 * isAllCollisionsResolved
 */

const taskDeliverableFileValidators = [getFileMaxSizeValidator({ maxSize: 125000000 })];

export interface CreateTaskDeliverableFileModalProps {
    taskId: ID;
}

type Props = ModalProps<CreateTaskDeliverableFileModalProps>;
type FileData = {
    file: File;
    name: string;
    collisionWithId: ID | null;
    path: string;
};

const CreateTaskDeliverableFileModal: React.FC<Props> = ({ closeModal, data }) => {
    const authorizedUser = useStore($authorizedUser);
    const currentPathLocation = useStore($currentPathLocation);
    const taskDeliverablesTree = useStore($taskDeliverablesTree);

    const [isCreatingTaskDeliverables, setIsCreatingTaskDeliverables] = useState(false);
    const [fileNameToUpdateInformation, setFileNameToUpdateInformation] = useState<{
        path: string;
        name: string;
    } | null>(null);
    const [fileName, setFileName] = useState('');
    const [fileMap, setFileMap] = useState<{
        [absolutePath in string]: FileData[];
    }>({});

    const { upload, isUploading } = useUpload({
        clearFilesOnUploadSuccess: true,
        validators: taskDeliverableFileValidators,
        onUploadSuccess: async (fileInformationArray) => {
            if (!data || !authorizedUser) {
                return;
            }

            const fileDataMap: { [fileId in ID]: { data: FileData; resource: VerifiedFileResource } } = {};

            // Create task deliverable input
            const createTaskDeliverablesBatchInput: CreateTaskDeliverablesInput = { task_id: data.taskId, items: [] };
            for (let i = 0; i < fileInformationArray.length; i++) {
                // Get file information
                const fileInformation = fileInformationArray[i];
                if (!fileInformation.verifiedFileResource) {
                    continue;
                }

                // Get file data
                let fileData: FileData | null = null;
                for (const path in fileMap) {
                    // Get file data array
                    const fileDataArray = fileMap[path];

                    // Find file data
                    const foundFileData = fileDataArray.find(
                        (data) =>
                            data.file.name === fileInformation.file.name &&
                            (data.file as FileWithPath).path === (fileInformation.file as FileWithPath).path
                    );

                    if (foundFileData) {
                        fileData = foundFileData;
                        break;
                    }
                }

                if (!fileData) {
                    // For some reason file data was not found
                    continue;
                }

                // Put file data in the map
                fileDataMap[fileInformation.verifiedFileResource!.id] = {
                    data: fileData,
                    resource: fileInformation.verifiedFileResource!,
                };

                // Add new input
                createTaskDeliverablesBatchInput.items.push({
                    type: TaskDeliverableType.FILE,
                    file_id: fileInformation.verifiedFileResource!.id,
                    title: fileData.name,
                    path: fileData.path,
                    parent_task_deliverable_id: fileData.collisionWithId,
                });
            }

            // Create task deliverable and add it to task deliverable tree
            if (createTaskDeliverablesBatchInput.items.length > 0) {
                try {
                    // Upload task deliverables batch
                    const createTaskDeliverablesBatchResponse = await createTaskDeliverablesBatch(
                        createTaskDeliverablesBatchInput
                    );

                    // Create task deliverables
                    const taskDeliverables: TaskDeliverableResource[] = [];
                    const momentDateNowFormatted = moment().utc().format();
                    for (let i = 0; i < createTaskDeliverablesBatchInput.items.length; i++) {
                        const createTaskDeliverablesBatchInputItem = createTaskDeliverablesBatchInput.items[i];
                        const fileData = fileDataMap[createTaskDeliverablesBatchInputItem.file_id!];

                        if (!fileData) {
                            continue;
                        }

                        taskDeliverables.push({
                            id: createTaskDeliverablesBatchResponse.ids[i],
                            task_id: data.taskId,
                            approved_at: null,
                            archived_at: null,
                            replaced_at: null,
                            created_at: momentDateNowFormatted,
                            title: createTaskDeliverablesBatchInputItem.title!,
                            path: createTaskDeliverablesBatchInputItem.path,
                            type: createTaskDeliverablesBatchInputItem.type,
                            parent_task_deliverable: null,
                            text_content: '',
                            url: '',
                            file: {
                                id: fileData.resource.id,
                                extension: FileUtils.getFileExtension(fileData.data.file.name),
                                mime: fileData.data.file.type,
                                original_name: fileData.data.file.name,
                                preview_path: fileData.resource.preview_path,
                                url: fileData.resource.url,
                                size: fileData.data.file.size,
                            },
                            user: {
                                id: authorizedUser.user.id,
                                first_name: authorizedUser.user.first_name,
                                last_name: authorizedUser.user.last_name,
                                image_hash: authorizedUser.user.image_hash,
                                role: authorizedUser.user.role,
                            },
                            metadata: {
                                comment_count_total: 0,
                                comment_count_unresolved: 0,
                            },
                        });

                        // Remove deliverable if it was replaced by this new deliverable
                        if (createTaskDeliverablesBatchInputItem.parent_task_deliverable_id) {
                            taskDeliverablesTreeApi.removeTaskDeliverable({
                                path: createTaskDeliverablesBatchInputItem.path,
                                taskDeliverableId: createTaskDeliverablesBatchInputItem.parent_task_deliverable_id,
                            });
                        }
                    }

                    // Insert task deliverables data
                    taskDeliverablesTreeApi.insert({ taskDeliverables });
                } catch (e) {
                    throw e;
                }
            }

            closeModal?.();
        },
    });

    if (!data || !taskDeliverablesTree || !authorizedUser) {
        closeModal?.();
        return null;
    }

    const handleUploadFiles = async () => {
        setIsCreatingTaskDeliverables(true);

        // Get file to fileData map from fileMap created whenever attaching files before uploading
        const fileDataByNameMap: { [fileName in string]: FileData } = {};
        Object.values(fileMap).forEach((fileMapArray) =>
            fileMapArray.forEach((fileData) => {
                fileDataByNameMap[fileData.name] = fileData;
            })
        );

        // Upload files
        try {
            await upload(
                FilePurpose.TASK_DELIVERABLE,
                Object.values(fileDataByNameMap).map((data) => data.file)
            );
        } catch (e) {
            // TODO: handle error
            console.error(e);
            setIsCreatingTaskDeliverables(false);
            return;
        }
    };

    const handleAddFiles = (files: FileWithPath[]) => {
        let tempFileMap: { [absolutePath in string]: FileData[] } = { ...fileMap };

        for (const file of files) {
            // Get absolute path relative to currentPathLocation
            let absolutePath = '';
            if (currentPathLocation !== '/') {
                // Not home level, path should start with current path
                absolutePath = currentPathLocation;
            }

            if (file.path && file.path.startsWith('/')) {
                // File is NOT on home level, it is part of directory
                const parsedFilePath = file.path.split(`/${file.name}`);
                absolutePath += parsedFilePath[0];
            } else {
                if (absolutePath.length === 0) {
                    absolutePath = '/';
                }
            }

            // Check file map collisions at path
            const fileMapValues = fileMap[absolutePath];
            if (fileMapValues) {
                if (fileMapValues.some((f) => f.name === file.name)) {
                    continue;
                }
            }

            // Check deliverable collisions
            const fileData: FileData = {
                file,
                collisionWithId: null,
                name: file.name,
                path: absolutePath,
            };

            if (taskDeliverablesTree.deliverables[absolutePath]) {
                // Path found, need to check collisions in current path for uploaded file

                // Get deliverables
                const deliverables = Object.values(taskDeliverablesTree.deliverables[absolutePath]);

                // Find deliverables with the same name as uploaded file
                for (const deliverable of deliverables) {
                    if (deliverable.title === file.name) {
                        // Found collision
                        fileData.collisionWithId = deliverable.id;
                        break;
                    }
                }
            }

            // Add file file data
            if (tempFileMap[absolutePath]) {
                tempFileMap[absolutePath].push(fileData);
            } else {
                tempFileMap = Object.assign({ [absolutePath]: [fileData] }, tempFileMap);
            }
        }

        setFileMap(tempFileMap);
    };

    const renderRemoveFileButton = (path: string, name: string) => {
        const handleRemoveFile = () => {
            // Copy file map
            const copyFileMap = { ...fileMap };

            // Get files for path
            const fileDataArray = copyFileMap[path];
            if (!fileDataArray) {
                return;
            }

            // Get update file data array
            const filteredFileDataArray = fileDataArray.filter((file) => file.name !== name);

            if (filteredFileDataArray.length === 0) {
                // All files were removed from the path, no need to keep path
                delete copyFileMap[path];
            } else {
                // Update file map
                copyFileMap[path] = filteredFileDataArray;
            }

            setFileMap(copyFileMap);
        };

        return (
            <Tooltip content="Do not upload">
                <Button minimal icon="cross" intent={Intent.DANGER} onClick={handleRemoveFile} />
            </Tooltip>
        );
    };

    const renderFileNameInput = (path: string, name: string) => {
        if (
            !fileNameToUpdateInformation ||
            fileNameToUpdateInformation.path !== path ||
            fileNameToUpdateInformation.name !== name
        ) {
            return null;
        }

        const handleFinishUpdateFileName = () => {
            setFileNameToUpdateInformation(null);
            setFileName('');
        };

        const handleUpdateFileName = () => {
            if (fileName.trim().length !== 0) {
                // Copy file map
                const copyFileMap = { ...fileMap };

                // Get files for path
                const fileDataArray = copyFileMap[path];
                if (!fileDataArray) {
                    console.log('1');
                    return;
                }

                // Get file data
                const foundFileDataIndex = fileDataArray.findIndex((data) => data.name === name);
                if (foundFileDataIndex === -1) {
                    console.log('2');
                    // File data for key was NOT found
                    handleFinishUpdateFileName();
                    return;
                }
                const foundFileData = { ...fileDataArray[foundFileDataIndex] };

                // Check file map collisions at path
                const fileMapValues = fileMap[path];
                if (fileMapValues) {
                    if (fileMapValues.some((f) => f.name === fileName)) {
                        console.log('3');
                        handleFinishUpdateFileName();
                        return;
                    }
                }

                // Check collisions in deliverables
                if (taskDeliverablesTree.deliverables[path]) {
                    // Get deliverables
                    const deliverables = Object.values(taskDeliverablesTree.deliverables[path]);

                    // Find deliverable with the same name as new file name
                    for (const deliverable of deliverables) {
                        if (deliverable.title === fileName) {
                            // New name is already used in another deliverable
                            // TODO: handle error
                            handleFinishUpdateFileName();
                            console.log('4');
                            return;
                        }
                    }
                }

                // Update name
                foundFileData.name = fileName;

                // Name changes = new file = not replacing anyone
                foundFileData.collisionWithId = null;

                // Update file data array
                fileDataArray[foundFileDataIndex] = foundFileData;

                // Update file map
                setFileMap(copyFileMap);
            }

            handleFinishUpdateFileName();
        };

        return (
            <InputGroup
                autoFocus
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                rightElement={<Button intent={Intent.PRIMARY} icon="tick" onClick={handleUpdateFileName} />}
            />
        );
    };

    const renderFileName = (path: string, name: string) => {
        if (
            fileNameToUpdateInformation &&
            fileNameToUpdateInformation.name === name &&
            fileNameToUpdateInformation.path === path
        ) {
            return null;
        }

        const handleUpdateFileName = () => {
            setFileNameToUpdateInformation({ name, path });
            setFileName(name);
        };

        return (
            <Tooltip content={name}>
                <DevText onClick={handleUpdateFileName}>{TextFormatUtils.truncate(name, 60)}</DevText>
            </Tooltip>
        );
    };

    const renderDirectoryBlock = (path: string, fileDataArray: FileData[]) => {
        // Collect breadcrumbs
        const breadcrumbItems: BreadcrumbProps[] = [];
        breadcrumbItems.push({ text: rootTaskDeliverablesPathLabel });
        if (path !== '/') {
            const parsedPath = path.split('/');
            for (let i = 1; i < parsedPath.length; i++) {
                breadcrumbItems.push({
                    text: parsedPath[i],
                });
            }
        }

        return (
            <div key={path} className="mb-2">
                <Flex className="mb-1" direction="row" align="center" justify="space-between">
                    <Breadcrumbs
                        items={breadcrumbItems}
                        currentBreadcrumbRenderer={({ text, ...restProps }: BreadcrumbProps) => (
                            <Breadcrumb {...restProps}>{text}</Breadcrumb>
                        )}
                    />
                </Flex>

                <div>
                    {fileDataArray.map((data, index) => (
                        <Card
                            compact
                            className={index === 0 ? '' : 'mt-1'}
                            elevation={Elevation.ONE}
                            style={{ padding: '8px' }}
                        >
                            <Flex direction="row" align="center" justify="space-between">
                                <Flex direction="row" align="center">
                                    <Box className="mr-1" width="56px" height="56px" minWidth="56px" minHeight="56px">
                                        <FilePreview
                                            width="100%"
                                            height="100%"
                                            minWidth="100%"
                                            minHeight="100%"
                                            objectFit="cover"
                                            extension={FileUtils.getFileExtension(data.file.name)}
                                            src={URL.createObjectURL(data.file)}
                                        />
                                    </Box>

                                    <Flex className="mr-small" direction="column" justify="center">
                                        <div className="mb-small">
                                            {renderFileName(path, data.name)}
                                            {renderFileNameInput(path, data.name)}
                                        </div>
                                        <DevText muted>{FileUtils.getFormattedFileSize(data.file.size)}</DevText>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" align="center">
                                    {data.collisionWithId !== null && (
                                        <Popover
                                            interactionKind="hover"
                                            content={
                                                <Card compact style={{ maxWidth: '300px' }}>
                                                    <Heading type="h5" className="mb-2">
                                                        Deliverable already exists
                                                    </Heading>

                                                    <DevText running>
                                                        Uploaded file will replace current one. To upload without
                                                        replacement, change file name before uploading by clicking
                                                        current file name.
                                                    </DevText>
                                                </Card>
                                            }
                                        >
                                            <Tag
                                                large
                                                minimal
                                                interactive
                                                icon="rotate-document"
                                                intent={Intent.WARNING}
                                            >
                                                Replacement
                                            </Tag>
                                        </Popover>
                                    )}
                                    <div className="ml-1">{renderRemoveFileButton(path, data.name)}</div>
                                </Flex>
                            </Flex>
                        </Card>
                    ))}
                </div>
            </div>
        );
    };

    const renderFiles = () => {
        if (Object.keys(fileMap).length === 0) {
            return null;
        }

        const directoryBlocks: JSX.Element[] = [];
        for (const path in fileMap) {
            // Get file data for path
            const fileData = Object.values(fileMap[path]);

            // Add block to render
            directoryBlocks.push(renderDirectoryBlock(path, fileData));
        }

        return <div className="mt-2">{directoryBlocks}</div>;
    };

    const renderFileCallout = () => {
        if (isCreatingTaskDeliverables) {
            return null;
        }

        if (Object.keys(fileMap).length === 0) {
            return null;
        }

        return (
            <Callout className="mt-2" icon={null} intent={Intent.WARNING}>
                <DevText>
                    Keep in mind that files you've dropped here will be uploaded only after you clicked "Upload" button.
                </DevText>
            </Callout>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '900px', maxWidth: '100%' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Upload file deliverable(s)</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <DropzoneArea
                    visible={!isCreatingTaskDeliverables}
                    loading={isUploading}
                    className="mb-1"
                    uploadFiles={handleAddFiles}
                >
                    <Flex fullHeight fullWidth direction="column" align="center" justify="center">
                        <Icon className="mb-small" icon="upload" size={48} />
                        <Heading type="h6">Drop file(s) or directory here to upload</Heading>
                    </Flex>
                </DropzoneArea>

                {renderFileCallout()}

                {renderFiles()}

                <Flex className="mt-2" justify="flex-end">
                    <Button className="mr-1" outlined onClick={closeModal}>
                        Cancel
                    </Button>

                    <Button
                        disabled={isCreatingTaskDeliverables}
                        loading={isCreatingTaskDeliverables}
                        type="submit"
                        intent={Intent.PRIMARY}
                        onClick={handleUploadFiles}
                    >
                        Upload
                    </Button>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default CreateTaskDeliverableFileModal;
