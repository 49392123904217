import styled, { css } from 'styled-components';
import { Colors } from '@blueprintjs/core';

export const Overview = styled.p`
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    font-style: italic;
    color: ${Colors.WHITE};
`;

export const Label = styled.span<{ $isActive: boolean }>`
    color: ${Colors.WHITE};

    & + & {
        margin-left: 2px;
    }

    ${({ $isActive }) =>
        $isActive &&
        css`
            color ${Colors.BLUE5};
            text-decoration: underline;
        `};
`;
