import CategoryConfiguration from '@app/containers/pages/AiImageGenerator/types/CategoryConfiguration';
import { IllustrationDesignCategoryPromptKeys } from '../types';

const configuration: CategoryConfiguration<IllustrationDesignCategoryPromptKeys> = {
    promptTemplate: 'A #vibe#, #color#, #style# illustration of #subject# #setting# using #medium# #surface#',
    inputs: {
        subject: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'input',
            label: 'What is the subject or context of the illustration?',
            placeholder: 'A white yeti',
            defaultOptions: [],
            initialValue: '',
        },
        setting: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What is the location setting of the subject?',
            placeholder: 'Location setting',
            defaultOptions: [
                'in a cave',
                'in a city',
                'in a fantasy world',
                'in an alleyway',
                'in nature',
                'in space',
                'in the sky',
                'indoors',
                'inside a building',
                'inside a house',
                'on abstract background',
                'on black background',
                'on grass',
                'on the desert',
                'on the streets',
                'on white background',
                'outdoors',
                'underwater',
            ].sort(),
            initialValue: 'on white background',
        },
        style: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'In what illustration style?',
            placeholder: 'Style',
            defaultOptions: [
                '3D',
                'anime',
                'caricature',
                'cartoon',
                'comic',
                'fantasy',
                'flat',
                'freehand',
                'hyper-realistic',
                'minimalistic',
                'pixel-art',
                'realistic',
                'sketch',
                'surreal',
            ].sort(),
            initialValue: 'realistic',
        },
        surface: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What drawing surface is used?',
            placeholder: 'Surface',
            defaultOptions: [
                'acrylic',
                'airbrush',
                'charcoal',
                'colored pen',
                'crayon',
                'digital',
                'graphite',
                'ink',
                'pastel',
                'pen and ink',
                'pencil',
                'spray paint',
                'watercolor',
            ].sort(),
            initialValue: 'digital',
        },
        medium: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'Where is the drawing applied? On what medium?',
            placeholder: 'Medium',
            defaultOptions: [
                'a sketchpad',
                'a wall',
                'canvas',
                'cloth',
                'paper',
                'plastic',
                'textured paper',
                'wood',
            ].sort(),
            initialValue: 'paper',
        },
        vibe: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What is the emotional vibe of the image?',
            placeholder: 'Vibe',
            defaultOptions: [
                'brooding',
                'dramatic',
                'energetic',
                'happy',
                'intense',
                'melancholic',
                'muted',
                'mysterious',
                'optimistic',
                'plain',
                'serene',
                'somber',
                'vibrant',
            ].sort(),
            initialValue: 'plain',
        },
        color: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'How much color? How bright or dark is the illustration?',
            placeholder: 'Color',
            defaultOptions: [
                'black and white',
                'bright',
                'colorful',
                'dark',
                'monochromatic',
                'neon',
                'soft',
                'vibrant',
            ].sort(),
            initialValue: 'bright',
        },
    },
    getFinalPrompt: (props: IllustrationDesignCategoryPromptKeys) => {
        return `A ${props.vibe.text}, ${props.color.text}, ${props.style.text} illustration of ${props.subject.text} ${props.setting.text} using ${props.medium.text} ${props.surface.text}`;
    },
};

export default configuration;
