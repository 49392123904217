import { QuotaType } from 'dy-frontend-http-repository/lib/data/enums';

const quotaTypeInformation: { [quotaType in QuotaType]: string } = {
    [QuotaType.USER_ACTIVE_TASK]: 'Active requests',
    [QuotaType.USER_BACKLOG_TASK]: 'Backlog requests',
    [QuotaType.USER_BRAND_PROFILE]: 'Brand profiles',
    [QuotaType.USER_AI_IMG_PERIOD_TOKEN]: 'Max AI image tokens per-period',
};

export default quotaTypeInformation;
