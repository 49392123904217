import CategoryConfiguration from '@app/containers/pages/AiImageGenerator/types/CategoryConfiguration';
import { ArtworkCategoryPromptKeys } from '../types';

const configuration: CategoryConfiguration<ArtworkCategoryPromptKeys> = {
    promptTemplate:
        'A #vibe#, #lighting#, #artMovement# #type# of #subject#, #setting#, #time#, in the style of #style#',
    inputs: {
        subject: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'input',
            label: 'What is the subject or context of the artwork?',
            placeholder: 'A white yeti',
            defaultOptions: [],
            initialValue: '',
        },
        setting: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'Where is the location setting of the subject?',
            placeholder: 'Location setting',
            defaultOptions: [
                'in a cave',
                'in a city',
                'in a fantasy world',
                'in an alleyway',
                'in nature',
                'in space',
                'in the sky',
                'indoors',
                'inside a building',
                'inside a house',
                'on abstract background',
                'on black background',
                'on grass',
                'on the desert',
                'on the streets',
                'on white background',
                'outdoors',
                'underwater',
            ].sort(),
            initialValue: 'on white background',
        },
        type: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What type of artwork?',
            placeholder: 'Type',
            defaultOptions: [
                'acrylic painting',
                'airbrush painting',
                'charcoal drawing',
                'colored pen illustration',
                'digital artwork',
                'graffiti',
                'graphite drawing',
                'ink drawing',
                'oil painting',
                'pastel drawing',
                'pen and ink drawing',
                'pencil drawing',
                'spray painting',
                'watercolor painting',
            ].sort(),
            initialValue: 'digital artwork',
        },
        artMovement: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What art movement?',
            placeholder: 'Art movement',
            defaultOptions: [
                'abstractionism',
                'art deco',
                'art nouveau',
                'cartoon',
                'classicism',
                'comic',
                'conceptual art',
                'cubism',
                'dadaism',
                'digital artwork',
                'expressionism',
                'hyper-realism',
                'impressionism',
                'op art',
                'pop art',
                'realism',
                'renaissance',
                'street art',
                'surrealism',
            ].sort(),
            initialValue: 'realism',
        },
        vibe: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What is the emotional vibe of the artwork?',
            placeholder: 'Vibe',
            defaultOptions: [
                'brooding',
                'dramatic',
                'energetic',
                'happy',
                'intense',
                'melancholic',
                'muted',
                'mysterious',
                'optimistic',
                'plain',
                'serene',
                'somber',
                'vibrant',
            ].sort(),
            initialValue: 'plain',
        },
        lighting: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'How is the subject lit? Where from? How much light?',
            placeholder: 'Lighting',
            defaultOptions: [
                'artificial lighting',
                'backlighting',
                'bright lighting',
                'butterfly lighting',
                'camera flash',
                'dramatic lighting',
                'low-light',
                'minimal lighting',
                'natural lighting',
                'overhead lighting',
                'rembrandt lighting',
                'semi-silhouette lighting',
                'short lighting',
                'split lighting',
                'under-lighting',
            ].sort(),
            initialValue: 'natural lighting',
        },
        time: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'Artificial or natural light? What time of day?',
            placeholder: 'Time',
            defaultOptions: [
                'at night',
                'at sunrise',
                'at sunset',
                'during twilight',
                'in the afternoon',
                'in the evening',
                'in the morning',
                'on the blue hour',
                'on the golden hour',
            ].sort(),
            initialValue: 'in the afternoon',
        },
        style: {
            isUsedInPromptOverview: true,
            isCustomOptionAllowed: true,
            inputType: 'select',
            label: 'What style is used? What artist?',
            placeholder: 'Style',
            defaultOptions: [
                'Andy Warhol',
                'Banksy',
                'Beeple',
                'Blek le Rat',
                'Claude Monet',
                'FEWOCiOUS',
                'Keith Haring',
                'King Robbo',
                'Leonardo da Vinci',
                'Pablo Picasso',
                'Rembrandt',
                'Roy Lichtenstein',
                'Salvador Dali',
                'Trevor Jones',
                'Vincent van Gogh',
            ].sort(),
            initialValue: 'Vincent van Gogh',
        },
    },
    getFinalPrompt: (props: ArtworkCategoryPromptKeys) => {
        return `A ${props.vibe.text}, ${props.lighting.text}, ${props.artMovement.text} ${props.type.text} of ${props.subject.text}, ${props.setting.text}, ${props.time.text}, in the style of ${props.style.text}`;
    },
};

export default configuration;
