import React from 'react';
import { useStore } from 'effector-react';
import { $plan } from '@pages/Plan/store/states';
import { PlanQuotaAttachmentResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import { Button, Card } from '@blueprintjs/core';
import DevText from '@components/Text';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { openModal } from '@modals/store/events';
import SetPlanQuotaListModal, { SetPlanQuotaListModalProps } from '@pages/Plan/modals/SetPlanQuotaListModal';
import { QuotaMergeMode, QuotaType } from 'dy-frontend-http-repository/lib/data/enums';
import { $permissions } from '@containers/store/states';
import { PlanPermission } from 'dy-frontend-permissions/lib/permission';
import { quotaTypeInformation } from '@app/data/consts';

// TODO: make in reusable
// Section: List of quota attachments
const QuotaList: React.FC = () => {
    const permissions = useStore($permissions);

    const plan = useStore($plan);

    if (!plan) {
        return null;
    }

    // Renders single quota attachment item
    const renderItem = (item: PlanQuotaAttachmentResource) => {
        // Compute quantity label
        let quotaQuantityLabel = <DevText inline>{item.value}</DevText>;
        if (item.is_quantity_multiply_allowed) {
            quotaQuantityLabel = (
                <DevText inline>
                    {item.value}{' '}
                    <DevText inline muted>
                        x [quantity]
                    </DevText>
                </DevText>
            );
        }

        // TODO: use quota information instead
        // Resolve quota name
        const quotaReadableName = quotaTypeInformation[item.type] ?? 'Unknown quota';

        // Compute label
        let label = (
            <DevText>
                {item.merge_mode} "{item.type}" to "{item.value}"
            </DevText>
        );
        switch (item.merge_mode) {
            case QuotaMergeMode.SET:
                label = (
                    <DevText>
                        Set "{quotaReadableName}" to {quotaQuantityLabel}
                    </DevText>
                );
                break;
            case QuotaMergeMode.SUM:
                label = (
                    <DevText>
                        Add {quotaQuantityLabel} to "{quotaReadableName}" amount
                    </DevText>
                );
                break;
        }

        return (
            <Card className="mb-1">
                <div className="mb-small">{label}</div>
                {item.is_quantity_multiply_allowed && <DevText muted>Scales with licence quantity</DevText>}
            </Card>
        );
    };

    // Renders current quota item list
    const renderList = (list: PlanQuotaAttachmentResource[]) => {
        if (!list.length) {
            return (
                <div className="mt-1">
                    <DevText muted>No quota adjustments added...</DevText>
                </div>
            );
        }

        return list.map((i) => <div key={`plan-quota-${i.type}`}>{renderItem(i)}</div>);
    };

    // Renders button, invoking attachment list update flow
    const renderUpdateAction = () => {
        const isAllowed = permissions.isRoot.plan || permissions.has(PlanPermission.ATTACHMENT_MANAGE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                onClick={() =>
                    openModal<SetPlanQuotaListModalProps>({
                        ModalComponent: SetPlanQuotaListModal,
                        data: {
                            planId: plan.id,
                            initialAttachmentList: plan.quotas,
                        },
                    })
                }
            >
                Change
            </Button>
        );
    };

    return (
        <div>
            <Flex justify="space-between">
                <Heading type="h4">Quota list / Limitations</Heading>
                {renderUpdateAction()}
            </Flex>
            <DevText muted className="mb-1">
                What usage is allowed by this plan
            </DevText>
            {renderList(plan.quotas)}
        </div>
    );
};

export default QuotaList;
