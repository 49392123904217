import PageTabs from '@app/components/PageTabs';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Endpoints } from '@app/data/consts';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { tabs } from './consts';
import { $task } from '@pages/Task/store/states';

type Props = HTMLAttributes<HTMLDivElement>;

const Tabs: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);

    const task = useStore($task);

    const { taskId } = useParams();

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const [isTaskMessagesEnabled, setIsTaskMessageEnabled] = useState(false);
    const [isTaskDeliverablesEnabled, setIsTaskDeliverablesEnabled] = useState(false);
    const [isTaskManagementEnabled, setIsTaskManagementEnabled] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        // Task messages
        setIsTaskMessageEnabled(permissions.isEnabled.taskMessage || permissions.isRoot.taskMessage);

        // Task deliverables
        setIsTaskDeliverablesEnabled(permissions.isEnabled.taskDeliverable || permissions.isRoot.taskDeliverable);

        // Task management
        if (permissions.isRoot.task) {
            setIsTaskManagementEnabled(true);
        } else {
            if (permissions.has(TaskPermission.PRIORITY_MANAGE) || permissions.has(TaskPermission.COMPLEXITY_MANAGE)) {
                setIsTaskManagementEnabled(true);
            } else {
                const isAssigned = task.participants.find(p => p.user.id === me.user.id) !== undefined;
                setIsTaskManagementEnabled(isAssigned && (
                    permissions.has(TaskPermission.PRIORITY_MANAGE_MOD_ASSIGNED)
                    || permissions.has(TaskPermission.COMPLEXITY_MANAGE_MOD_ASSIGNED)
                ));
            }
        }

    }, [task, permissions, me]);

    const tabVisibilityPermissions = useMemo<{
        [pageEndpoint in keyof typeof Endpoints]?: boolean;
    }>(() => {

        return {
            [Endpoints.TASK_INFORMATION]: true,
            [Endpoints.TASK_MESSAGES]: isTaskMessagesEnabled,

            // Deliverables
            [Endpoints.TASK_DELIVERABLES]: isTaskDeliverablesEnabled,

            // If root on task, or one of the management permissions are present
            [Endpoints.TASK_MANAGEMENT]: isTaskManagementEnabled,
        };
    }, [isTaskMessagesEnabled, isTaskDeliverablesEnabled, isTaskManagementEnabled]);

    const getFormattedPath = (path: string) => {
        return path.replace(':taskId', taskId!);
    };

    const filteredTabsByPermissions = tabs.filter((tab) => {
        const tabPermission: boolean | undefined =
            tabVisibilityPermissions[tab.value as (typeof Endpoints)[keyof typeof Endpoints]];

        if (tabPermission === undefined) {
            // Tab visibility permission was NOT found for tab, do NOT show it then
            return false;
        }

        return tabPermission;
    });

    return (
        <PageTabs
            pathname={pathname}
            visibleAmount={5}
            tabs={filteredTabsByPermissions}
            onSelectTab={navigate}
            pathFormatter={getFormattedPath}
            {...props}
        />
    );
};

export default Tabs;
