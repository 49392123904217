import Grid from '@app/components/Grid';
import { Card, Colors, FormGroup, InputGroup, MenuItem } from '@blueprintjs/core';
import { AiImageCategory } from 'dy-frontend-http-repository/lib/modules/AiImage/enums';
import React, { useEffect, useState } from 'react';
import PromptOverview from '../../PromptOverview';
import { configuration } from './consts';
import { RealisticPhotographyCategoryPromptKeys } from './types';
import Select from '@components/Select';

interface RealisticPhotographyGeneratorProps {
    isGenerating: boolean;
    onPromptChange: (prompt: string | null) => void;
}

type Props = RealisticPhotographyGeneratorProps;

const RealisticPhotographyGenerator: React.FC<Props> = ({ isGenerating, onPromptChange }) => {
    const [focusedInputKey, setFocusedInputKey] = useState<string | null>(null);
    const [subject, setSubject] = useState('');
    const [framing, setFraming] = useState(
        configuration.inputs['framing'] ? configuration.inputs['framing'].initialValue : ''
    );
    const [vibe, setVibe] = useState(configuration.inputs['vibe'] ? configuration.inputs['vibe'].initialValue : '');
    const [angle, setAngle] = useState(configuration.inputs['angle'] ? configuration.inputs['angle'].initialValue : '');
    const [lighting, setLighting] = useState(
        configuration.inputs['lighting'] ? configuration.inputs['lighting'].initialValue : ''
    );
    const [time, setTime] = useState(configuration.inputs['time'] ? configuration.inputs['time'].initialValue : '');
    const [lensAndCamera, setLensAndCamera] = useState(
        configuration.inputs['lensAndCamera'] ? configuration.inputs['lensAndCamera'].initialValue : ''
    );
    const [shootContext, setShootContext] = useState(
        configuration.inputs['shootContext'] ? configuration.inputs['shootContext'].initialValue : ''
    );
    const [process, setProcess] = useState(
        configuration.inputs['process'] ? configuration.inputs['process'].initialValue : ''
    );
    const [year, setYear] = useState(configuration.inputs['year'] ? configuration.inputs['year'].initialValue : '');
    const [usageContext, setUsageContext] = useState(
        configuration.inputs['usageContext'] ? configuration.inputs['usageContext'].initialValue : ''
    );

    const getCategoryPromptKeys = (): RealisticPhotographyCategoryPromptKeys => {
        const overviewRendererProps: RealisticPhotographyCategoryPromptKeys = {
            angle: { isActive: false, key: '', text: '' },
            framing: { isActive: false, key: '', text: '' },
            lensAndCamera: { isActive: false, key: '', text: '' },
            lighting: { isActive: false, key: '', text: '' },
            process: { isActive: false, key: '', text: '' },
            shootContext: { isActive: false, key: '', text: '' },
            subject: { isActive: false, key: '', text: '' },
            time: { isActive: false, key: '', text: '' },
            usageContext: { isActive: false, key: '', text: '' },
            vibe: { isActive: false, key: '', text: '' },
            year: { isActive: false, key: '', text: '' },
        };

        for (const inputKey of Object.keys(configuration.inputs)) {
            // Prompt property not used in overview
            if (!configuration.inputs[inputKey].isUsedInPromptOverview) {
                continue;
            }

            const value = promptPropertiesData[inputKey].value;
            const elementKey = `${AiImageCategory.PHOTO}_${inputKey}`;

            // No input is focused
            if (focusedInputKey === null) {
                overviewRendererProps[inputKey] = {
                    key: elementKey,
                    text: value,
                    isActive: false,
                };
                continue;
            }

            // Input for property "key" is focused
            if (focusedInputKey === inputKey) {
                overviewRendererProps[inputKey] = {
                    key: elementKey,
                    text: value,
                    isActive: true,
                };
                continue;
            }

            overviewRendererProps[inputKey] = {
                key: elementKey,
                text: value,
                isActive: false,
            };
        }

        return overviewRendererProps;
    };

    useEffect(() => {
        if (subject.trim().length === 0) {
            onPromptChange(null);
            return;
        }

        onPromptChange(configuration.getFinalPrompt(getCategoryPromptKeys()));
    }, [subject, framing, vibe, angle, lighting, time, lensAndCamera, shootContext, process, year, usageContext]);

    const promptPropertiesData: {
        [key in string]: { value: string; dispatch: React.Dispatch<React.SetStateAction<string>> };
    } = {
        subject: { value: subject, dispatch: setSubject },
        framing: { value: framing, dispatch: setFraming },
        vibe: { value: vibe, dispatch: setVibe },
        angle: { value: angle, dispatch: setAngle },
        lighting: { value: lighting, dispatch: setLighting },
        time: { value: time, dispatch: setTime },
        lensAndCamera: { value: lensAndCamera, dispatch: setLensAndCamera },
        shootContext: { value: shootContext, dispatch: setShootContext },
        process: { value: process, dispatch: setProcess },
        year: { value: year, dispatch: setYear },
        usageContext: { value: usageContext, dispatch: setUsageContext },
    };

    const renderPrompt = () => {
        return (
            <Card compact style={{ background: Colors.DARK_GRAY2 }}>
                <PromptOverview
                    template={configuration.promptTemplate}
                    categoryPromptConfiguration={getCategoryPromptKeys()}
                />
            </Card>
        );
    };

    const renderGeneratorFields = () => {
        const filterItemOption = (query: string, item: string) => {
            return item.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderCreateItemOption = (
            query: string,
            active: boolean,
            handleClick: React.MouseEventHandler<HTMLElement>
        ) => {
            return (
                <MenuItem
                    icon="add"
                    text={`${query}`}
                    active={active}
                    onClick={handleClick}
                    shouldDismissPopover={false}
                />
            );
        };

        return (
            <Grid container>
                {Object.entries(configuration.inputs).map(([key, promptPropertyInputConfiguration]) => {
                    if (promptPropertyInputConfiguration.inputType === 'input') {
                        return (
                            <Grid lg={6}>
                                <FormGroup label={promptPropertyInputConfiguration.label}>
                                    <InputGroup
                                        disabled={isGenerating}
                                        placeholder={promptPropertyInputConfiguration.placeholder}
                                        value={promptPropertiesData[key].value}
                                        onChange={(e) => promptPropertiesData[key].dispatch(e.target.value)}
                                        onFocus={() => setFocusedInputKey(key)}
                                    />
                                </FormGroup>
                            </Grid>
                        );
                    } else {
                        let items: string[] = promptPropertyInputConfiguration.defaultOptions;
                        const canAddCustomOption = promptPropertyInputConfiguration.isCustomOptionAllowed;

                        const renderItemOption = (item: string, { handleClick }) => {
                            return (
                                <MenuItem
                                    key={item}
                                    text={item}
                                    onClick={handleClick}
                                    active={item === promptPropertiesData[key].value}
                                />
                            );
                        };

                        return (
                            <Grid lg={6}>
                                <FormGroup label={promptPropertyInputConfiguration.label}>
                                    <Select<string>
                                        fill
                                        filterable
                                        key={key}
                                        items={items}
                                        disabled={isGenerating}
                                        itemPredicate={filterItemOption}
                                        createNewItemFromQuery={canAddCustomOption ? (query) => query : undefined}
                                        createNewItemRenderer={canAddCustomOption ? renderCreateItemOption : undefined}
                                        itemRenderer={renderItemOption}
                                        onItemSelect={(item) => promptPropertiesData[key].dispatch(item)}
                                        popoverProps={{
                                            onOpening: () => setFocusedInputKey(key),
                                        }}
                                        inputProps={{
                                            placeholder: 'Add keywords / Search',
                                        }}
                                        selectButtonProps={{
                                            fill: true,
                                            text: promptPropertiesData[key].value,
                                            rightIcon: 'double-caret-vertical',
                                        }}
                                    />
                                </FormGroup>
                            </Grid>
                        );
                    }
                })}
            </Grid>
        );
    };

    return (
        <div>
            <div className="mb-2">{renderPrompt()}</div>
            <div>{renderGeneratorFields()}</div>
        </div>
    );
};

export default RealisticPhotographyGenerator;
