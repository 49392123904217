import { TabItem } from '@app/data/types';
import { Endpoints } from '@data/consts';

const tabs: TabItem[] = [
    { icon: 'saved', value: Endpoints.CLIENT_TASKS, label: 'Requests' },
    { icon: 'helper-management', value: Endpoints.CLIENT_BRAND_PROFILES, label: 'Brand profiles' },
    { icon: 'chart', value: Endpoints.CLIENT_TASK_FEEDBACK, label: 'Request Feedback' },
    { icon: 'clipboard', value: Endpoints.CLIENT_NOTES, label: 'Notes' },
    { icon: 'bank-account', value: Endpoints.CLIENT_BILLING, label: 'Billing' },
    { icon: 'box', value: Endpoints.CLIENT_LICENCES, label: 'Plans / Features' },
    { icon: 'ring', value: Endpoints.CLIENT_CREDITS, label: 'Credits' },
    { icon: 'inbox-update', value: Endpoints.CLIENT_NOTIFICATIONS, label: 'Notifications' },
    { icon: 'media', value: Endpoints.CLIENT_AI_IMAGES, label: 'AI Images' },
    { icon: 'cog', value: Endpoints.CLIENT_SETTINGS, label: 'Settings' },
];

export default tabs;
