import styled, { css } from 'styled-components';

export const ImagePositioner = styled.div<{ $overlayText: string }>`
    position: relative;
    z-index: 1;

    cursor: pointer;

    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    &:hover {
        &::after {
            ${({ $overlayText }) => css`
                content: '${$overlayText}';
            `}

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;

            width: 100%;
            height: 100%;
            text-align: center;
            background: rgba(0, 0, 0, 0.85);
        }
    }
`;

export const ImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;
`;
