import { createApi } from 'effector';
import { AiImageResource } from 'dy-frontend-http-repository/lib/modules/AiImage/resources';
import { $aiImages } from './states';

// API to manage ai images
export const aiImagesApi = createApi($aiImages, {
    // Initialize AI images
    initialize: (store, aiImages: AiImageResource[]) => {
        return aiImages;
    },

    // Bookmark AI image
    bookmark: (store, id: ID) => {
        if (store.length === 0) {
            return store;
        }

        // Copy AI Images
        const aiImagesCopy = store.slice(0);

        // Find AI Image
        const foundAiImage = aiImagesCopy.find((image) => image.id === id);
        if (!foundAiImage) {
            return store;
        }

        // Update bookmark flag
        foundAiImage.is_caller_bookmark = true;

        return aiImagesCopy;
    },

    // Remove AI image bookmark
    removeBookmark: (store, id: ID) => {
        if (store.length === 0) {
            return store;
        }

        // Copy AI Images
        const aiImagesCopy = store.slice(0);

        // Find AI Image
        const foundAiImage = aiImagesCopy.find((image) => image.id === id);
        if (!foundAiImage) {
            return store;
        }

        // Update bookmark flag
        foundAiImage.is_caller_bookmark = false;

        return aiImagesCopy;
    },
});
