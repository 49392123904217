import {
    $clientPaymentAccount,
    $isTaskDeliverablesTreeInitialized,
    $task,
    $taskAddons,
    $taskComplexity,
    $taskDeliverables,
    $taskDeliverablesTree,
    $taskPickableParticipantUsers,
    $taskStateTransitionLog,
    $teams,
} from './states';
import {
    fetchClientPaymentAccount,
    fetchPickableTaskParticipantUsers,
    fetchTask,
    fetchTaskAddons,
    fetchTaskComplexity,
    fetchTaskDeliverables,
    fetchTaskPublishInformation,
    fetchTaskStateTransitionLog,
    fetchTeams,
} from './effects';
import '../modals/PublishTaskModal/store/init';
import '../modals/TaskDeliverableViewerModal/store/init';
import '../pages/TaskInformation/modals/UpdateTaskInformationModal/store/init';
import '../pages/TaskMessages/store/init';
import '../pages/TaskDeliverables/store/init';
import { setIsTaskDeliverablesTreeInitialized } from './events';

// Task deliverables tree initialization flag
$isTaskDeliverablesTreeInitialized.on(setIsTaskDeliverablesTreeInitialized, (_, data) => data);

// Task pickable participant user list
$taskPickableParticipantUsers.on(fetchPickableTaskParticipantUsers.doneData, (_, data) => data);

// Task
$task.on(fetchTask.doneData, (_, data) => data);

// Task publish information
$task.on(fetchTaskPublishInformation.doneData, (task, taskPublishInformation) => {
    if (!task) {
        return task;
    }

    return {
        ...task,
        publish: taskPublishInformation,
    };
});

// Fetch task state transition log
$taskStateTransitionLog.on(fetchTaskStateTransitionLog.doneData, (_, data) => data);

// Fetch teams
$teams.on(fetchTeams.doneData, (_, data) => data);

// Fetch task complexity
$taskComplexity.on(fetchTaskComplexity.doneData, (_, data) => data);

// Fetch task addons
$taskAddons.on(fetchTaskAddons.doneData, (_, data) => data);

// Client payment account
$clientPaymentAccount.on(fetchClientPaymentAccount.doneData, (_, data) => data);

// Fetch task deliverables
$taskDeliverables.on(fetchTaskDeliverables.doneData, (_, data) => {
    // Get deliverables which were NOT replaced
    const parentDeliverableIds: ID[] = [];
    data.items.forEach((deliverable) => {
        if (deliverable.parent_task_deliverable !== null) {
            // Current deliverable replaces something
            parentDeliverableIds.push(deliverable.parent_task_deliverable.id);
        }
    });
    const taskDeliverableItems = data.items.filter((deliverable) => !parentDeliverableIds.includes(deliverable.id));

    return {
        ...data,
        items: taskDeliverableItems,
    };
});
