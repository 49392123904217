import {
    Button,
    Icon,
    Card as BlueprintjsCard,
    Colors,
    Tag,
    Tooltip,
    Popover,
    Spinner,
    Intent,
} from '@blueprintjs/core';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { ModalProps } from '@modals/types';
import React, { useEffect, useState } from 'react';
import { Card, DismissModalPanel, ImageViewerWrapper, ModalContent } from './styled';
import Image from '@components/Image';
import Overlay from '@components/Overlay';
import Box from '@app/components/Box';
import { AiImageResource } from 'dy-frontend-http-repository/lib/modules/AiImage/resources';
import { ViewerWrapper } from '../FileViewerModal/styled';
import Avatar from '@app/components/Avatar';
import {
    aiImageModelInformation,
    aiImageQualityInformation,
    Endpoints,
    imageHashPreview,
    userRoleInformation,
} from '@app/data/consts';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import RouterLink from '@app/components/RouterLink';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { $aiImages } from './store/states';
import { useStore } from 'effector-react';
import { aiImagesApi } from './store/apis';
import { bookmarkAiImage, removeAiImageBookmark } from './store/effects';

export interface AiImageViewerModalProps {
    currentFileIndex: number;
    images: AiImageResource[];
}

type Props = ModalProps<AiImageViewerModalProps>;

const AiImageViewerModal: React.FC<Props> = ({ closeModal, data }) => {
    const aiImages = useStore($aiImages);

    const [currentFileItemIndex, setCurrentFileItemIndex] = useState(data ? data.currentFileIndex : 0);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isBookmarkActionProcessing, setIsBookmarkActionProcessing] = useState(false);

    console.log('aiImages: ', aiImages);

    const handleEscapeKeyPressed = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            closeModal?.();
        }
    };

    const handleCloseModal = () => {
        closeModal?.();
    };

    useEffect(() => {
        // Initialize AI images
        if (!data) {
            return;
        }

        aiImagesApi.initialize(data.images);
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPressed);
        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPressed);
        };
        // eslint-disable-next-line
    }, []);

    if (!data || data.images.length === 0) {
        // Data was NOT provided or files is empty array, meaning nothing to view
        closeModal?.();
        return null;
    }

    if (aiImages.length === 0) {
        return null;
    }

    const renderImagePromptInformation = (image: AiImageResource) => {
        return (
            <BlueprintjsCard>
                <Flex className="mb-1" direction="row">
                    <DevText muted>Prompt: </DevText>
                    <DevText>{image.prompt}</DevText>
                </Flex>

                <Flex className="mb-1" direction="row">
                    <DevText muted>AI Model: </DevText>
                    <DevText>{aiImageModelInformation[image.model].name}</DevText>
                </Flex>

                <Flex className="mb-1" direction="row">
                    <DevText muted>Size: </DevText>
                    <DevText>
                        {image.width}x{image.height}
                    </DevText>
                </Flex>

                <Flex className="mb-1" direction="row">
                    <DevText muted>AI Model: </DevText>
                    <DevText>{aiImageQualityInformation[image.quality].name}</DevText>
                </Flex>
            </BlueprintjsCard>
        );
    };

    const renderAiImage = (image: AiImageResource) => {
        if(!image) {
            return null;
        }

        // Get first & last first/last item flag for current file item
        const isCurrentFileItemFirst = currentFileItemIndex === 0;
        const isCurrentFileItemLast = currentFileItemIndex === aiImages.length - 1;

        const handleGoToPreviousAiImageItem = () => {
            setIsImageLoaded(false);

            if (isCurrentFileItemFirst) {
                // First element
                setCurrentFileItemIndex(aiImages.length - 1);
                return;
            }

            setCurrentFileItemIndex((i) => i - 1);
        };

        const handleGoToNextAiImageItem = () => {
            setIsImageLoaded(false);

            if (isCurrentFileItemLast) {
                // Last element
                setCurrentFileItemIndex(0);
                return;
            }

            setCurrentFileItemIndex((i) => i + 1);
        };

        const handleRemoveAiImageBookmark = async () => {
            setIsBookmarkActionProcessing(true);

            try {
                await removeAiImageBookmark(image.id);
                aiImagesApi.removeBookmark(image.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setIsBookmarkActionProcessing(false);
            }
        };

        const handleBookmarkAiImage = async () => {
            setIsBookmarkActionProcessing(true);

            try {
                await bookmarkAiImage(image.id);
                aiImagesApi.bookmark(image.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setIsBookmarkActionProcessing(false);
            }
        };

        const renderPreviousAiImageItemButton = () => {
            return <Button minimal icon="chevron-left" onClick={handleGoToPreviousAiImageItem} />;
        };

        const renderAiImageItemIndexCounter = () => {
            return (
                <DevText>
                    {currentFileItemIndex + 1} / {aiImages.length}
                </DevText>
            );
        };

        const renderNextAiImageItemButton = () => {
            return <Button minimal icon="chevron-right" onClick={handleGoToNextAiImageItem} />;
        };

        const renderAiImageItemIndexControls = () => {
            if (aiImages.length <= 1) {
                return null;
            }

            return (
                <Flex className="ml-1" direction="row" align="center">
                    <div className="mr-1">{renderPreviousAiImageItemButton()}</div>
                    <div className="mr-1">{renderAiImageItemIndexCounter()}</div>
                    <div>{renderNextAiImageItemButton()}</div>
                </Flex>
            );
        };

        const renderCopyAiImageItemSrcButton = () => {
            return (
                <Popover
                    content={
                        <BlueprintjsCard compact>
                            <DevText>Copied</DevText>
                        </BlueprintjsCard>
                    }
                >
                    <Button minimal icon="duplicate" onClick={() => navigator.clipboard.writeText(image.file.url)}>
                        Copy link
                    </Button>
                </Popover>
            );
        };

        const renderDownloadFileItemButton = () => {
            return (
                <Button minimal icon="import" onClick={() => window.open(image.file.url)}>
                    Download
                </Button>
            );
        };

        const renderBookmarkControlButton = () => {
            if (image.is_caller_bookmark) {
                // Bookmarked
                return (
                    <Button
                        loading={isBookmarkActionProcessing}
                        icon="cross"
                        intent={Intent.DANGER}
                        onClick={handleRemoveAiImageBookmark}
                    >
                        Remove bookmark
                    </Button>
                );
            } else {
                // Not Bookmarked
                return (
                    <Button
                        loading={isBookmarkActionProcessing}
                        icon="bookmark"
                        intent={Intent.PRIMARY}
                        onClick={handleBookmarkAiImage}
                    >
                        Bookmark
                    </Button>
                );
            }
        };

        const renderViewer = () => {
            return (
                <ViewerWrapper className="custom-thin-scroll">
                    <ImageViewerWrapper>
                        {!isImageLoaded && <Spinner />}
                        <Image
                            objectFit="contain"
                            src={image.file.url}
                            alt={image.file.original_name}
                            style={{ display: isImageLoaded ? 'block' : 'none' }}
                            onLoad={() => setIsImageLoaded(true)}
                        />
                    </ImageViewerWrapper>
                </ViewerWrapper>
            );
        };

        const renderFooter = () => {
            let userLink: string | null = null;
            switch (image.user.type) {
                case UserType.CLIENT:
                    userLink = Endpoints.CLIENT_AI_IMAGES.replace(':clientId', image.user.id);
                    break;
                case UserType.CORE:
                    userLink = Endpoints.CORE_USER_TASKS.replace(':coreUserId', image.user.id);
                    break;
            }

            return (
                <Box width="100%" padding="8px" backgroundColor={Colors.DARK_GRAY1}>
                    <Flex justify="space-between" align="center">
                        <Flex direction="row" align="center">
                            <Flex direction="row" align="center">
                                <Avatar
                                    className="mr-1"
                                    width="45px"
                                    height="45px"
                                    minHeight="45px"
                                    minWidth="45px"
                                    maxHeight="45px"
                                    maxWidth="45px"
                                    src={
                                        image.user.image_hash
                                            ? imageHashPreview.userImage(image.user.image_hash, ImageHashPreviewSize.SM)
                                            : null
                                    }
                                    alt={`${image.user.first_name} ${image.user.last_name}`}
                                />

                                <Flex direction="column" justify="center">
                                    <DevText>
                                        {userLink === null ? (
                                            <>
                                                {image.user.first_name} {image.user.last_name}
                                            </>
                                        ) : (
                                            <RouterLink to={userLink} color="inherit">
                                                {image.user.first_name} {image.user.last_name}
                                            </RouterLink>
                                        )}
                                    </DevText>

                                    <DevText muted>{userRoleInformation[image.user.role].label}</DevText>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex direction="row" align="center">
                            <div className="mr-1">
                                <Popover content={renderImagePromptInformation(image)}>
                                    <Tag minimal large interactive icon="info-sign">
                                        Prompt
                                    </Tag>
                                </Popover>
                            </div>
                            <div className="mr-1">{renderCopyAiImageItemSrcButton()}</div>
                            <div className="mr-1">{renderDownloadFileItemButton()}</div>
                            {renderBookmarkControlButton()}
                            {renderAiImageItemIndexControls()}
                        </Flex>
                    </Flex>
                </Box>
            );
        };

        return (
            <Card onClick={(e) => e.stopPropagation()}>
                <Flex fullWidth fullHeight direction="column" align="flex-start" justify="flex-start">
                    {renderViewer()}
                    {renderFooter()}
                </Flex>
            </Card>
        );
    };

    const renderDismissModalPanel = () => {
        return (
            <DismissModalPanel onClick={handleCloseModal}>
                <Flex fullHeight fullWidth align="center" justify="center">
                    <Icon className="mr-1" icon="chevron-up" />
                    <DevText>Click here to close</DevText>
                    <Icon className="ml-1" icon="chevron-up" />
                </Flex>
            </DismissModalPanel>
        );
    };

    // Get current file item
    const currentFileItem = aiImages[currentFileItemIndex];

    return (
        <Overlay isOpen noPadding height="100%" onClose={closeModal}>
            <ModalContent>
                {renderDismissModalPanel()}
                {renderAiImage(currentFileItem)}
            </ModalContent>
        </Overlay>
    );
};

export default AiImageViewerModal;
