export const RouteTypes = {
    AUTH: '/authentication',
    APP: '/app',
};

const Endpoints = {
    /* Routes */
    AUTH_ROUTE: RouteTypes.AUTH,
    APP: RouteTypes.APP,

    HOME: `${RouteTypes.APP}/`,

    /* Authentication */
    AUTHENTICATION: `${RouteTypes.AUTH}/`,

    /* Client */
    CLIENTS: `${RouteTypes.APP}/clients`,
    CLIENT: `${RouteTypes.APP}/client/:clientId`,
    CLIENT_NOTES: `${RouteTypes.APP}/client/:clientId/notes`,
    CLIENT_BRAND_PROFILES: `${RouteTypes.APP}/client/:clientId/brand-profiles`,
    CLIENT_TASKS: `${RouteTypes.APP}/client/:clientId/tasks`,
    CLIENT_STATISTICS: `${RouteTypes.APP}/client/:clientId/statistics`,
    CLIENT_SETTINGS: `${RouteTypes.APP}/client/:clientId/settings`,
    CLIENT_BILLING: `${RouteTypes.APP}/client/:clientId/billing`,
    CLIENT_CREDITS: `${RouteTypes.APP}/client/:clientId/credits`,
    CLIENT_NOTIFICATIONS: `${RouteTypes.APP}/client/:clientId/notifications`,
    CLIENT_TASK_FEEDBACK: `${RouteTypes.APP}/client/:clientId/task-feedback`,
    CLIENT_LICENCES: `${RouteTypes.APP}/client/:clientId/licences`,
    CLIENT_AI_IMAGES: `${RouteTypes.APP}/client/:clientId/ai-images`,

    /* Core user */
    CORE_USERS: `${RouteTypes.APP}/core-users`,
    CORE_USER: `${RouteTypes.APP}/core-user/:coreUserId`,
    CORE_USER_TASKS: `${RouteTypes.APP}/core-user/:coreUserId/tasks`,
    CORE_USER_ESCALATION_LOG: `${RouteTypes.APP}/core-user/:coreUserId/escalation-log`,
    CORE_USER_SETTINGS: `${RouteTypes.APP}/core-user/:coreUserId/settings`,
    CORE_USER_STATISTICS: `${RouteTypes.APP}/core-user/:coreUserId/statistics`,
    CORE_USER_TASK_FEEDBACK: `${RouteTypes.APP}/core-user/:coreUserId/task-feedback`,

    /* Task categories */
    TASK_CATEGORIES: `${RouteTypes.APP}/task-categories`,
    TASK_CATEGORY: `${RouteTypes.APP}/task-category/:taskCategoryId`,
    TASK_CATEGORY_GROUPS: `${RouteTypes.APP}/task-category-groups`,
    TASK_CATEGORY_FEATURED_BLOCKS: `${RouteTypes.APP}/task-category-featured-blocks`,

    /* Task feedback */
    TASK_CLIENT_FEEDBACK: `${RouteTypes.APP}/task-client-feedback`,

    /* Credit codes */
    CREDIT_CODES: `${RouteTypes.APP}/credit-codes`,

    /* Plans */
    PLANS: `${RouteTypes.APP}/plans`,
    PLAN: `${RouteTypes.APP}/plan/:planId`,

    /* Perks */
    PERKS: `${RouteTypes.APP}/perks`,

    /* Perk */
    PERK: `${RouteTypes.APP}/perk/:perkId`,

    /* Task message templates */
    TASK_MESSAGE_TEMPLATES: `${RouteTypes.APP}/task-message-templates`,

    /* Announcements */
    ANNOUNCEMENTS: `${RouteTypes.APP}/announcements`,

    /* Brand profile */
    BRAND_PROFILE: `${RouteTypes.APP}/brand-profile/:brandProfileId`,

    /* Task kanban board */
    TASK_KANBAN_BOARD: `${RouteTypes.APP}/task-kanban-board`,

    /* Task */
    TASKS: `${RouteTypes.APP}/tasks`,
    TASK: `${RouteTypes.APP}/task/:taskId`,
    TASK_INFORMATION: `${RouteTypes.APP}/task/:taskId/information`,
    TASK_MESSAGES: `${RouteTypes.APP}/task/:taskId/messages`,
    TASK_DELIVERABLES: `${RouteTypes.APP}/task/:taskId/deliverables`,
    TASK_DELIVERABLES_ALL: `${RouteTypes.APP}/task/:taskId/deliverables`,
    TASK_DELIVERABLES_APPROVED: `${RouteTypes.APP}/task/:taskId/deliverables/pending`,
    TASK_DEV_DELIVERABLES: `${RouteTypes.APP}/task/:taskId/dev-deliverables`,
    TASK_MANAGEMENT: `${RouteTypes.APP}/task/:taskId/management`,

    /* Team */
    TEAMS: `${RouteTypes.APP}/teams`,
    TEAM: `${RouteTypes.APP}/team/:teamId`,
    TEAM_MEMBERS: `${RouteTypes.APP}/team/:teamId/members`,
    TEAM_STATISTICS: `${RouteTypes.APP}/team/:teamId/statistics`,
    TEAM_TASKS: `${RouteTypes.APP}/team/:teamId/tasks`,
    TEAM_TASK_FEEDBACK: `${RouteTypes.APP}/team/:teamId/task-feedback`,

    /* Workspace */
    WORKSPACES: `${RouteTypes.APP}/workspaces`,
    WORKSPACE: `${RouteTypes.APP}/workspace/:workspaceId`,
    WORKSPACE_PORTAL: `${RouteTypes.APP}/workspace/:workspaceId/portal`,
    WORKSPACE_MEMBERS: `${RouteTypes.APP}/workspace/:workspaceId/members`,
    WORKSPACE_BILLING: `${RouteTypes.APP}/workspace/:workspaceId/billing`,
    WORKSPACE_LICENCES: `${RouteTypes.APP}/workspace/:workspaceId/licences`,
    WORKSPACE_SETTINGS: `${RouteTypes.APP}/workspace/:workspaceId/settings`,
    WORKSPACE_ROUTER: `${RouteTypes.AUTH}/workspace-router`,
    WORKSPACE_SIGNUP: `${RouteTypes.AUTH}/workspace-signup`,

    // Workspace user
    WORKSPACE_USER: `${RouteTypes.APP}/workspace-user/:workspaceUserId`,
    WORKSPACE_USER_INFORMATION: `${RouteTypes.APP}/workspace-user/:workspaceUserId/information`,

    /* Subscription */
    SUBSCRIPTION: `${RouteTypes.APP}/subscription/:subscriptionId`,

    /* AI image generator */
    AI_IMAGE_GENERATOR: `${RouteTypes.APP}/ai-image-generator`,
    AI_IMAGES: `${RouteTypes.APP}/ai-images`,

    // Lazy client overview
    LAZY_CLIENT_OVERVIEW: `${RouteTypes.APP}/lazy-client-overview`,

    /* Others */
    UI_KIT: `${RouteTypes.APP}/ui-kit`,
    TEST_FILE_UPLOAD: `${RouteTypes.APP}/test-file-upload`,
    PLAYGROUND: `${RouteTypes.APP}/playground`,
};

export default Endpoints;
