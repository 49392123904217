import { FormGroup, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import React, { useEffect, useState } from 'react';
import Select from '@components/Select';
import Grid from '@components/Grid';
import { useStore } from 'effector-react';
import { $modelSchemas } from '../../store/states';
import { ModelSchemaResource } from 'dy-frontend-http-repository/lib/modules/AiImage/resources';
import { AiImageModel, AiImageQuality, AiImageStyle } from 'dy-frontend-http-repository/lib/modules/AiImage/enums';
import { aiImageModelInformation } from '../../consts';

interface AiModelSelectProps {
    isGenerating: boolean;
    onModelChange: (model: AiImageModel | null) => void;
    onSizeChange: (size: string | null) => void;
    onQualityChange: (quality: AiImageQuality | null) => void;
    onStyleChange: (style: AiImageStyle | null) => void;
}
type Props = AiModelSelectProps;

const AiModelSelect: React.FC<Props> = ({
    isGenerating,
    onModelChange,
    onSizeChange,
    onQualityChange,
    onStyleChange,
}) => {
    const modelSchemas = useStore($modelSchemas);

    const [model, setModel] = useState<ModelSchemaResource | null>(null);
    const [size, setSize] = useState<string | null>(null);
    const [quality, setQuality] = useState<AiImageQuality | null>(null);
    const [style, setStyle] = useState<AiImageStyle | null>(null);

    const [sizeItems, setSizeItems] = useState<string[]>([]);
    const [qualityItems, setQualityItems] = useState<AiImageQuality[]>([]);
    const [styleItems, setStyleItems] = useState<AiImageStyle[]>([]);

    const [isSizeSelectVisible, setIsSizeSelectVisible] = useState(false);
    const [isQualitySelectVisible, setIsQualitySelectVisible] = useState(false);
    const [isStyleSelectVisible, setIsStyleSelectVisible] = useState(false);

    useEffect(() => {
        if (!modelSchemas || modelSchemas.items.length === 0) {
            return;
        }

        if (!model) {
            return;
        }

        // Set size
        setIsSizeSelectVisible(model.size.length > 1);
        setSize(model.default_size);
        setSizeItems(model.size);

        // Set quality
        setIsQualitySelectVisible(model.quality.length > 1);
        setQuality(model.default_quality);
        setQualityItems(model.quality);

        // Set styles
        setIsStyleSelectVisible(model.style.length > 1);
        setStyle(model.default_style);
        setStyleItems(model.style);
    }, [modelSchemas, model]);

    useEffect(() => {
        onSizeChange(size);
    }, [size]);

    useEffect(() => {
        onQualityChange(quality);
    }, [quality]);

    useEffect(() => {
        onStyleChange(style);
    }, [style]);

    useEffect(() => {
        onModelChange(model ? model.model : null);
    }, [model]);

    if (!modelSchemas || modelSchemas.items.length === 0) {
        return null;
    }

    const renderModelSelect = () => {
        const renderItem = (item: ModelSchemaResource, { handleClick }: ItemRendererProps) => {
            const isActive = item.model === model?.model;

            return (
                <MenuItem
                    active={isActive}
                    key={item.model}
                    text={aiImageModelInformation[item.model].name}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: ModelSchemaResource) => {
            setModel(item);
        };

        return (
            <FormGroup label="AI Model">
                <Select<ModelSchemaResource>
                    fill
                    filterable
                    disabled={isGenerating}
                    activeItem={model}
                    items={modelSchemas.items}
                    selectButtonProps={{
                        fill: true,
                        text: model ? aiImageModelInformation[model.model].name : 'Select AI Model',
                        placeholder: 'Select AI Model',
                    }}
                    popoverProps={{ usePortal: false }}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                />
            </FormGroup>
        );
    };

    const renderSizeSelect = () => {
        if (!isSizeSelectVisible) {
            return null;
        }

        const renderItem = (item: string, { handleClick }: ItemRendererProps) => {
            const isActive = item === size;

            return <MenuItem active={isActive} key={item} text={item} onClick={handleClick} />;
        };

        const handleItemSelect = (item: string) => {
            setSize(item);
        };

        return (
            <FormGroup label="Size">
                <Select<string>
                    fill
                    filterable
                    disabled={isGenerating || model === null}
                    activeItem={size}
                    items={sizeItems}
                    selectButtonProps={{
                        fill: true,
                        text: size ? size : 'Select size',
                        placeholder: 'Select size',
                    }}
                    popoverProps={{ usePortal: false }}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                />
            </FormGroup>
        );
    };

    const renderQualitySelect = () => {
        if (!isQualitySelectVisible) {
            return null;
        }

        const renderItem = (item: string, { handleClick }: ItemRendererProps) => {
            const isActive = item === quality;

            return <MenuItem active={isActive} key={item} text={item} onClick={handleClick} />;
        };

        const handleItemSelect = (item: string) => {
            setQuality(item as AiImageQuality);
        };

        return (
            <FormGroup label="Quality">
                <Select<string>
                    fill
                    filterable
                    disabled={isGenerating || model === null}
                    activeItem={quality}
                    items={qualityItems}
                    selectButtonProps={{
                        fill: true,
                        text: quality ? quality : 'Select quality',
                        placeholder: 'Select quality',
                    }}
                    popoverProps={{ usePortal: false }}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                />
            </FormGroup>
        );
    };

    const renderStyleSelect = () => {
        if (!isStyleSelectVisible) {
            return null;
        }

        const renderItem = (item: string, { handleClick }: ItemRendererProps) => {
            const isActive = item === style;

            return <MenuItem active={isActive} key={item} text={item} onClick={handleClick} />;
        };

        const handleItemSelect = (item: string) => {
            setStyle(item as AiImageStyle);
        };

        return (
            <FormGroup label="Style">
                <Select<string>
                    fill
                    filterable
                    disabled={isGenerating || model === null}
                    activeItem={style}
                    items={styleItems}
                    selectButtonProps={{
                        fill: true,
                        text: style ? style : 'Select style',
                        placeholder: 'Select style',
                    }}
                    popoverProps={{ usePortal: false }}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                />
            </FormGroup>
        );
    };

    return (
        <div>
            <Grid container>
                <Grid lg={6}>{renderModelSelect()}</Grid>
                <Grid lg={6}>{renderSizeSelect()}</Grid>
                <Grid lg={6}>{renderQualitySelect()}</Grid>
                <Grid lg={6}>{renderStyleSelect()}</Grid>
            </Grid>
        </div>
    );
};

export default AiModelSelect;
