import { AiImageCategory } from 'dy-frontend-http-repository/lib/modules/AiImage/enums';

const aiImageCategoryInformation: {
    [category in AiImageCategory]?: {
        name: string;
        description: string;
    };
} = {
    [AiImageCategory.ART_STYLE]: {
        name: 'Art Styles',
        description: 'Different artistic techniques and visual movements',
    },
    [AiImageCategory.ILLUSTRATION]: {
        name: 'Illustration Design',
        description: 'Creative, stylized digital and hand-drawn artwork',
    },
    [AiImageCategory.PHOTO]: {
        name: 'Realistic Photography',
        description: 'AI-generated lifelike photos with high detail',
    },
    [AiImageCategory.THREE_DIMENSIONAL]: {
        name: '3D Artwork',
        description: 'Three-dimensional digital models and renders',
    },
};

export default aiImageCategoryInformation;
