import { AiImageStyle } from 'dy-frontend-http-repository/lib/modules/AiImage/enums';

const aiImageStyleInformation: {
    [model in AiImageStyle]: {
        name: string;
    };
} = {
    [AiImageStyle.DEFAULT]: {
        name: 'default',
    },
    [AiImageStyle.NATURAL]: {
        name: 'natural',
    },
    [AiImageStyle.VIVID]: {
        name: 'vivid',
    },
};

export default aiImageStyleInformation;
